import classNames from "classnames/bind";

import style from "./Tooltip.module.scss";

const cx = classNames.bind(style);

function Tooltip(props) {
  const { children, className, rounded, text, maxWidth, classNameBox, ...restProps } = props;

  return (
    <div
      className={cx("tooltip-wrapper", "relative w-fit", className)}
      style={{
        borderRadius: `${rounded}px`,
      }}
      {...restProps}
    >
      {children}
      <div
        className={cx(
          "box-tooltip",
          "absolute shadow-[0px_0px_8px_rgba(0,0,0,0.08)] rounded-[10px] opacity-0 transition text-white p-3 max-w-[216px] pointer-events-none z-20 text-[12px] leading-[16px] font-normal",
          classNameBox
        )}
        style={{
          maxWidth: maxWidth,
        }}
      >
        {text}
      </div>
    </div>
  );
}

export default Tooltip;
