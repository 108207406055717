import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { memo } from "react";

import { useSurveyStore } from "~/store/SurveyContext";
import ChoiceResult from "./components/ChoiceResult";
import { surveyService } from "~/services/surveyService";


const typeQuestionCompUI = {
  single_choice: ChoiceResult,
  multi_choice: ChoiceResult,
};

function ResultTab(props) {
  const {
    dataSurvey: { id },
  } = useSurveyStore();
  const { data } = useQuery({
    queryKey: ["survey-result"],
    queryFn: async () => await surveyService.list(`/${id}/survey-result`),
  });

  const resultQuestion = useMemo(() => {
    const dataResult = data?.data;

    return dataResult;
  }, [data]);

  return (
    <div className="wrapper">
      {resultQuestion?.map((question, i) => {
        const Comp = typeQuestionCompUI[question.type];

        if (!Comp) return <></>

        return (
          <Comp
            key={question?._id}
            questionIndex={i}
            question={question}
            className="mt-5 first:mt-0"
          />
        );
      })}
    </div>
  );
}

export default memo(ResultTab);
