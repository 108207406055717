/* eslint-disable react-hooks/exhaustive-deps */
import classNames from "classnames/bind";
import { useEffect } from "react";
import { memo } from "react";

import {
  addAgeRange,
  addExceptCitySelected,
  addGenderSelected,
  addOnlyCitySelected,
  chooseAllCities,
} from "~/store/actions/ObjectInfoActions";
import Button from "~/components/Button";
import HeadingBig from "~/components/HeadingBig";
import SelectDropdownMultiCities from "~/components/SelectDropdownMultiCities";
import { convertDataCities, genderOptions } from "~/helper";
import useBasicInfo from "~/hooks/useBasicInfo";
import AgeRange from "./AgeRange";
import style from "../../Segment.module.scss";
import CheckboxGroup from "~/components/CheckboxGroup";
import { useSurveyStore } from "~/store/SurveyContext";

const cx = classNames.bind(style);

const locationSelection = [
  { name: "Tất cả tỉnh", key: "all" },
  { name: "Chỉ các tỉnh", key: "only" },
  { name: "Tất cả, trừ các tỉnh thành", key: "ignore" },
];

function BasicInfo(props) {
  const { dispatch } = props;
  const {
    dataSurvey: { onlyView },
  } = useSurveyStore();
  const {
    locationActive,
    setLocationActive,
    ageRange,
    watch,
    control,
    dataCities,
    contentRef,
    isOpen,
  } = useBasicInfo();
  const { gender, citiesSelected } = watch();

  useEffect(() => {
    dispatch(addGenderSelected(gender));
  }, [gender]);

  useEffect(() => {
    if (locationActive === "all") {
      dispatch(chooseAllCities());
    }
  }, [locationActive]);

  useEffect(() => {
    const valuesSelectedFormat = citiesSelected.map((item) => item.value);

    if (locationActive === "only") {
      dispatch(addOnlyCitySelected(valuesSelectedFormat));
      return;
    }

    if (locationActive === "ignore") {
      dispatch(addExceptCitySelected(valuesSelectedFormat));
      return;
    }

    dispatch(chooseAllCities());
  }, [citiesSelected]);

  const handleDataRange = (data) => {
    dispatch(addAgeRange(data));
  };

  return (
    <div className={cx("section")}>
      <HeadingBig
        className={cx("heading")}
        title="Nhân khẩu học cơ bản (bắt buộc)"
      />
      <div className={cx("field")}>
        <div className={cx("flex justify-between items-center")}>
          <div className={cx("title-14", "max-w-[132px] w-full")}>Địa điểm</div>
          <div className={cx("selection", "flex")}>
            {locationSelection.map((area, i) => {
              return (
                <Button
                  key={i}
                  variant={area.key === locationActive ? "fill" : "outline"}
                  className={cx(
                    "!rounded-full px-[14px] py-2 ml-3 hover:border-primary hover:bg-primary hover:!text-white",
                    area.key !== locationActive &&
                      "!border-[#E6E6E6] !text-[#333333]",
                    onlyView && "grayscale pointer-events-none"
                  )}
                  onClick={() => setLocationActive(area.key)}
                >
                  {area.name}
                </Button>
              );
            })}
          </div>
        </div>
        <div
          className={cx("tab-panels transition", !isOpen && "overflow-hidden")}
          ref={contentRef}
        >
          <div className="panel">
            <SelectDropdownMultiCities
              options={convertDataCities(dataCities)}
              defaultValue={convertDataCities(watch("citiesSelected"))}
              disabled={locationActive === "all"}
              control={control}
              name="citiesSelected"
              className="mt-3"
            />
          </div>
        </div>
      </div>
      <div className="flex mt-5">
        <div className={cx("field")}>
          <div className={cx("title-14", "max-w-[132px] w-full")}>
            Giới tính
          </div>
          <div className="flex items-center mt-3">
            <CheckboxGroup
              columns={3}
              name="gender"
              options={genderOptions}
              control={control}
              className="gap-5"
              disabled={onlyView}
            />
          </div>
        </div>
        <AgeRange handleDataRange={handleDataRange} ageRange={ageRange} />
      </div>
    </div>
  );
}

export default memo(BasicInfo);
