import classNames from "classnames/bind";
import { memo } from "react";

import BoxStatus from "./components/BoxStatus";
import BoxTime from "./components/BoxTime";
import BoxUser from "./components/BoxUser";
import style from "./ProgressTab.module.scss";

const cx = classNames.bind(style);

function ProgressTab(props) {
  const { className } = props;

  return (
    <div className={cx("wrapper", className)}>
      <BoxStatus />
      <BoxUser />
      <BoxTime />
    </div>
  );
}

export default memo(ProgressTab);
  