import { useMutation, useQuery } from "@tanstack/react-query";
import classNames from "classnames/bind";
import { useMemo } from "react";
import { memo } from "react";
import { useParams } from "react-router-dom";

import Label from "~/assets/icon/Label";
import BoxShadow from "~/components/BoxShadow";
import Button from "~/components/Button";
import { surveyService } from "~/services/surveyService";
import { useSurveyStore } from "~/store/SurveyContext";

export const statusColorLabelClass = {
  happenning: "text-info",
  completed: "text-primary",
  pending_approve: "text-warning",
};

function BoxStatus(props) {
  const { surveyId } = useParams();
  const {
    dataSurvey: { id = surveyId },
  } = useSurveyStore();

  const { data, refetch } = useQuery({
    queryKey: ["survey-progress"],
    queryFn: async () => await surveyService.list(`/${id}/survey-progress`),
  });

  const { mutate } = useMutation({
    mutationFn: async () => await surveyService.create(`/${id}/finish`),
    onSuccess(data) {
      refetch()
    },
  });

  const {
    status = "pending_approve",
    statusText = "Đang chờ duyệt",
    paymentStatus = false,
    paymentStatusText = "Đang chờ thành toán",
  } = useMemo(() => {
    return data?.data || {};
  }, [data]);

  return (
    <>
      <BoxShadow className="p-[30px] flex">
        <div className="flex items-center">
          <Label
            className={classNames("label-icon", "w-[38px]")}
            fillColorClassName={classNames(statusColorLabelClass[status])}
          />
          <p
            className={classNames(
              "ml-4 text-[16px] leading-[20px] font-semibold",
              statusColorLabelClass[status]
            )}
          >
            <span className="block">Nghiên cứu</span>
            <span>{statusText}</span>
          </p>
        </div>
        <div className="text-[14px] leading-[18px] mx-[62px]">
          <p className="text-[#B3B3B3]">Bảng câu hỏi & đối tượng</p>
          <p
            className={classNames(
              "mt-[6px] font-semibold",
              status === "pending_approve" ? "text-warning" : "text-primary"
            )}
          >
            {statusText}
          </p>
        </div>
        <div className="text-[14px] leading-[18px]">
          <p className="text-[#B3B3B3]">Thanh toán</p>
          <p
            className={classNames(
              "mt-[6px] font-semibold",
              paymentStatus ? "text-primary" : "text-warning"
            )}
          >
            {paymentStatusText}
          </p>
        </div>
        {status === "happenning" && (
          <Button
            className="text-[#B3B3B3] hover:text-[red] border border-current rounded-[6px] ml-auto flex items-center justify-between p-3"
            onClick={() => {
              mutate();
            }}
          >
            <span>Ngừng nghiên cứu</span>
            <svg
              className="w-4 ml-[6px]"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                className="fill-current"
                d="M5.05503 5.05503C5.32839 4.78166 5.77161 4.78166 6.04497 5.05503L8 7.01005L9.95502 5.05503C10.2284 4.78166 10.6716 4.78166 10.945 5.05503C11.2183 5.32839 11.2183 5.77161 10.945 6.04497L8.98995 8L10.945 9.95502C11.2183 10.2284 11.2183 10.6716 10.945 10.945C10.6716 11.2183 10.2284 11.2183 9.95502 10.945L8 8.98995L6.04497 10.945C5.77161 11.2183 5.32839 11.2183 5.05503 10.945C4.78166 10.6716 4.78166 10.2284 5.05503 9.95502L7.01005 8L5.05503 6.04497C4.78166 5.77161 4.78166 5.32839 5.05503 5.05503Z"
                fill="#B3B3B3"
              />
              <path
                className="fill-current"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8ZM8 2.4C4.90721 2.4 2.4 4.90721 2.4 8C2.4 11.0928 4.90721 13.6 8 13.6C11.0928 13.6 13.6 11.0928 13.6 8C13.6 4.90721 11.0928 2.4 8 2.4Z"
                fill="#B3B3B3"
              />
            </svg>
          </Button>
        )}
      </BoxShadow>
    </>
  );
}

export default memo(BoxStatus);
