import classNames from "classnames/bind";
import { memo } from "react";

import style from "./InitSurvey.module.scss";

const cx = classNames.bind(style);

function FieldForm({ label, children, className }) {
  return (
    <div className={cx("field", className)}>
      <label className={cx("title-14", "block mb-3")}>{label}</label>
      {children}
    </div>
  );
}

export default memo(FieldForm);
