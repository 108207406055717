import classNames from "classnames/bind";
import { memo } from "react";
import Dragable from "~/assets/icon/Dragable";

function QuestionItem(props) {
  const { index, question, active, onClick } = props;

  return (
    <li
      className={classNames(
        "py-[10px] cursor-pointer text-[#B3B3B3] hover:text-primary hover:font-semibold transition-all",
        active && "!text-primary font-semibold"
      )}
      onClick={onClick}
    >
      <a
        href={`#question-${index}`}
        className="flex items-center justify-between"
      >
        <div className="flex-1 mr-2 overflow-hidden text-ellipsis whitespace-nowrap	">
          0{index} - {question}
        </div>
        <div className="w-5 h-5">
          <Dragable className="h-full" />
        </div>
      </a>
    </li>
  );
}

export default memo(QuestionItem);
