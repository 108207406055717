import classNames from "classnames/bind";
import { memo } from "react";

import style from './LabelRounded.module.scss'

const cx = classNames.bind(style)

function LabelRounded(props) {
  const { title, type, className, color } = props

  return (
    <div
      className={cx(
        "flex justify-center items-center w-fit rounded-full p-2 border-current",
        !type && "text-white bg-primary",
        type === 'outline' && 'border',
        className
      )}
    >
      {title}
    </div>
  );
}

export default memo(LabelRounded);
