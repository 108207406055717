import { memo } from "react";

function Edit({ className }) {
  return (
    <svg
      className={className}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.9534 2.77758L14.0802 3.66188C14.5422 4.01885 14.8503 4.48939 14.9556 4.98427C15.0772 5.52864 14.9475 6.06328 14.5828 6.52571L7.87089 15.101C7.56286 15.4904 7.10892 15.7094 6.62255 15.7175L3.94753 15.75C3.80162 15.75 3.68003 15.6526 3.6476 15.5147L3.03964 12.9105C2.93427 12.4319 3.03964 11.937 3.34768 11.5557L8.10597 5.47104C8.18703 5.37369 8.33294 5.35828 8.43022 5.43048L10.4324 7.00437C10.5621 7.10984 10.7405 7.16663 10.9269 7.14229C11.3241 7.09361 11.5916 6.73665 11.5511 6.35534C11.5268 6.16063 11.4295 5.99838 11.2998 5.87669C11.2593 5.84423 9.35431 4.33525 9.35431 4.33525C9.23272 4.23789 9.2084 4.05941 9.30568 3.93853L10.0595 2.97229C10.7567 2.08799 11.9726 2.00687 12.9534 2.77758Z"
        fill="#999999"
      />
    </svg>
  );
}

export default memo(Edit);
