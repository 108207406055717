/* eslint-disable react-hooks/exhaustive-deps */
import classNames from "classnames/bind";
import { Fragment } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";

import BackToTop from "~/assets/icon/BackToTop";
import Button from "~/components/Button";
import useBackToTop from "~/hooks/useBackToTop";
import SurveyProvider from "~/store/Provider/SurveyProvider";
import Header from "../components/Header";
import SidebarLeft from "../components/SidebarLeft";

import style from "./SurveyInfo.module.scss";

const cx = classNames.bind(style);

const tabs = [
  { name: "Thông tin", path: "/create-survey" },
  { name: "Đối tượng", path: "/segment" },
  { name: "Bảng câu hỏi", path: "/create-questions" },
  { name: "Duyệt nghiên cứu", path: "/final-preview" },
  { name: "Kết quả", path: "/result-survey" },
];

function SurveyInfo({ children }) {
  const [stepOpen, setStepOpen] = useState(0);
  const navigate = useNavigate();
  const { surveyId } = useParams();
  const { pathname } = useLocation();
  const { contentScroll, handleBackToTop } = useBackToTop();

  useEffect(() => {
    const isCreatingSurvey =
      stepOpen === 0 && !pathname.includes("/create-survey") && !surveyId;

    if (isCreatingSurvey) navigate("/create-survey");
  }, []);

  return (
    <div className="wrapper flex flex-col h-full relative">
      <Header />
      <div className="flex flex-1 bg-[#F5F5F5]">
        <SidebarLeft />
        <div className={cx("flex-1 relative")}>
          <main className="absolute inset-0">
            <SurveyProvider setStepOpen={setStepOpen}>
              <div className="flex flex-col my-[20px]">
                <div className="tabs flex justify-center items-center mb-[28px] text-[#E6E6E6]">
                  {tabs.map((tab, i) => (
                    <Fragment key={i}>
                      <Button
                        className={cx(
                          "survey-tab",
                          "max-w-[140px] w-full py-3 relative rounded-[6px] hover:bg-primary hover:text-white font-semibold",
                          !pathname.includes(tab.path) && "bg-white",
                          stepOpen < i &&
                            stepOpen !== "all" &&
                            "grayscale pointer-events-none"
                        )}
                        variant={
                          pathname.includes(tab.path) ? "fill" : "outline"
                        }
                        type="link"
                        to={tab.path}
                      >
                        {tab.name}
                      </Button>
                      {i + 1 < tabs.length && (
                        <svg
                          className="w-6 h-6 mx-4"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M7.8295 4.82427C8.26884 4.39191 8.98116 4.39191 9.4205 4.82427L16.1705 11.4671C16.6098 11.8995 16.6098 12.6005 16.1705 13.0329L9.4205 19.6757C8.98116 20.1081 8.26884 20.1081 7.8295 19.6757C7.39017 19.2434 7.39017 18.5424 7.8295 18.11L13.784 12.25L7.8295 6.39001C7.39017 5.95764 7.39017 5.25664 7.8295 4.82427Z"
                            fill="#E6E6E6"
                          />
                        </svg>
                      )}
                    </Fragment>
                  ))}
                </div>
                <div
                  className={cx(
                    "main-content",
                    "absolute inset-0 top-[90px] overflow-auto pt-1"
                  )}
                  ref={contentScroll}
                >
                  {children}
                </div>
              </div>
            </SurveyProvider>
          </main>
        </div>
      </div>
      <Button
        className="absolute bottom-5 right-5 text-[#999999] hover:text-primary"
        onClick={handleBackToTop}
      >
        <BackToTop />
      </Button>
    </div>
  );
}

export default SurveyInfo;
