/* eslint-disable react-hooks/exhaustive-deps */
import { useQuery } from "@tanstack/react-query";
import classNames from "classnames/bind";
import { memo, useMemo } from "react";

import DashboardCategory from "./components/DashboardCategory";
import style from "./Dashboard.module.scss";
import { Link, useParams } from "react-router-dom";
import { surveyService } from "~/services/surveyService";

const cx = classNames.bind(style);

const surveyCategories = [
  {
    name: "Đang diễn ra",
    type: "happenning",
  },
  {
    name: "Đã hoàn thành",
    type: "completed",
  },
  {
    name: "Đang chờ duyệt",
    type: "pending_approve",
  },
];

function DashboardFilter(props) {
  const { surveyStatus } = useParams();
  const userId = localStorage.getItem("userId");

  const { data = {} } = useQuery({
    queryKey: [`${surveyStatus}-survey-list`],
    queryFn: async () =>
      await surveyService.list("", {
        status: surveyStatus,
        sortBy: "createdAt:desc",
        limit: 20,
        page: 1,
        onlySuitable: 0,
        memberId: userId,
      }),
  });

  const categoryName = useMemo(() => {
    const category = surveyCategories.find(
      (category) => category.type === surveyStatus
    );

    return category?.name;
  }, [data]);

  const surveyList = useMemo(() => {
    return data?.data?.results;
  }, [data]);

  return (
    <div
      className={cx("wrapper", "px-5 py-[30px] max-w-[1200px] w-full mx-auto")}
    >
      <div
        className={cx("welcome", "text-[20px] leading-[26px] font-semibold")}
      >
        <Link
          to="/"
          className="flex items-center gap-[6px] text-[#B3B3B3] text-[16px] leading-[20px] font-normal hover:text-primary transition"
        >
          <svg
            className="w-5"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              className="fill-current"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M13.4754 16.3964C13.1093 16.7567 12.5157 16.7567 12.1496 16.3964L6.52459 10.8607C6.15847 10.5004 6.15847 9.91625 6.52459 9.55595L12.1496 4.02023C12.5157 3.65993 13.1093 3.65993 13.4754 4.02023C13.8415 4.38054 13.8415 4.96471 13.4754 5.32502L8.51332 10.2083L13.4754 15.0917C13.8415 15.452 13.8415 16.0361 13.4754 16.3964Z"
              fill="#B3B3B3"
            />
          </svg>
          Dashboard
        </Link>
        <DashboardCategory
          categoryName={categoryName}
          surveyList={surveyList || []}
          type={surveyStatus}
        />
      </div>
    </div>
  );
}

export default memo(DashboardFilter);
