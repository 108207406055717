import { memo } from "react";
import { useFormContext } from "react-hook-form";

import Add from "~icon/Add";
import BoxShadow from "~/components/BoxShadow";
import Button from "~/components/Button";
import Copy from "~icon/Copy";
import Delete from "~/assets/icon/Delete";
import { singleChoice } from "~/helper/QuestionTypeData";
import { useSurveyStore } from "~/store/SurveyContext";
import classNames from "classnames";

function QuestionSidebarRight(props) {
  const { questionPicking, questionPickingPosition } = props;
  const {
    dataSurvey: { onlyView },
  } = useSurveyStore();
  const { append, watch, insert, remove } = useFormContext();

  const handleDuplicateQuestion = () => {
    const dataQuestions = watch("questions");

    if (dataQuestions.length === 0) return;

    const dataDuplicate = dataQuestions[questionPicking];
    insert(questionPicking, dataDuplicate);
  };

  const handleAddQuestion = () => {
    append(singleChoice());
  };

  return (
    <div className="max-w-[216px] w-full">
      <BoxShadow
        className={classNames(
          "p-[20px_16px_12px] h-fit relative transition-all",
          onlyView && "hidden"
        )}
        style={{ top: questionPickingPosition }}
      >
        <h3 className="title-14">Thao tác</h3>
        <Button
          iconLeft={<Add />}
          className={`py-2 mt-[6px] path-in-text text-[#999999] hover:text-primary transition ${onlyView && "pointer-events-none"}`}
          onClick={handleAddQuestion}
        >
          Tạo câu hỏi tiếp theo
        </Button>
        <Button
          iconLeft={<Copy />}
          className={`py-2 mt-[6px] path-in-text text-[#999999] hover:text-primary transition ${onlyView && "pointer-events-none"}`}
          onClick={handleDuplicateQuestion}
        >
          Sao chép câu hỏi
        </Button>
        <Button
          iconLeft={<Delete />}
          className={`py-2 mt-[6px] path-in-text text-[#999999] hover:text-primary transition ${onlyView && "pointer-events-none"}`}
          onClick={() => remove(questionPicking)}
        >
          Xoá câu hỏi
        </Button>
      </BoxShadow>
    </div>
  );
}

export default memo(QuestionSidebarRight);
