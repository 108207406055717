import classNames from "classnames/bind"
import { memo } from "react";

// import style from "./NotFound.module.scss"

// const cx = classNames.bind(style)

function NotFound(props) {
return (
    <div className="">
      Not found
    </div>
  )
}

export default memo(NotFound)