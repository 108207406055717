import { loginMan, waveLeft } from "~/assets/image/exportImage";

function LoginLayout({ children, image }) {
  return (
    <div className="wrapper flex justify-center items-center h-full bg-[#F5F5F5] ">
      <div className="max-w-[924px] w-full rounded-[20px] flex gap-5 bg-white relative">
        <img src={waveLeft} alt="wave" className="absolute top-1/2 -translate-y-1/2 right-full -translate-x-[30px]" />
        <img src={waveLeft} alt="wave" className="absolute top-1/2 -translate-y-1/2 left-full translate-x-[30px] rotate-180" />
        <div className="box-logo flex-1">
          <img src={loginMan} alt="loginman" />
        </div>
        <div className="box-content flex-1 w-full flex justify-center items-center py-10">
          {children}
        </div>
      </div>
    </div>
  );
}

export default LoginLayout;
