import { useReducer } from "react";

import surveyReducer from "~/store/reducer/surveyReducer";
import SurveyContext from "../SurveyContext";

const initDataSurvey = {
  name: '',
  description: '',
  status: "pending_approve",
  type: '',
  segment: [
    {
      name: "place",
      isAll: true,
      only: [], // number
      ignore: [] // number
    },
    {
      name: "gender",
      value: [
        'male'
      ]
    },
    {
      name: "age",
      "min": 20,
      "max": 40
    },
    {
      name: "job",
      value: [
      ]
    },
    {
      name: "income",
      value: [
      ]
    },
    {
      name: "marital",
      value: [
      ]
    }
  ],
  newQuestions: [
    {
      title: "Câu hỏi single choice",
      type: "single_choice",
      options: [
        'Option 1',
        'Option 2',
        'Option 3',
        'Option 4',
      ],
    },
  ],
  targetNumber: '500',
  reward: 10000,
}

function SurveyProvider({ children, ...restProps }) {
  const [dataSurvey, dispatch] = useReducer(surveyReducer, initDataSurvey)

  return (
    <SurveyContext.Provider
      value={{ dataSurvey, dispatch, ...restProps }}
    >
      {children}
    </SurveyContext.Provider>
  );
}

export default SurveyProvider;