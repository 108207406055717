/* eslint-disable react-hooks/exhaustive-deps */
import classNames from "classnames/bind";
import { useEffect } from "react";
import { memo } from "react";
import { useFormContext } from "react-hook-form";

import napasLogo from "~/assets/image/napas-logo.png";
import vietcombankLogo from "~/assets/image/vietcombank-logo.png";
import RadioInput from "~/components/RadioInput";
import useSlideToggle from "~/hooks/useSlideToggle";
import style from "../PaymentMethods/PaymentMethods.module.scss";
// import style from './BankPayment.module.scss'

const cx = classNames.bind(style);

function BankPayment(props) {
  const { control, watch } = useFormContext();
  const { toggle, contentRef, isOpen, slideClose, slideOpen } =
    useSlideToggle();
    const paymentMethod = watch("paymentMethod");

  useEffect(() => {
    if (paymentMethod === "bank_transfer") {
      slideOpen();

      return;
    }

    slideClose();
  }, [watch("paymentMethod")]);

  return (
    <div className={cx("method", paymentMethod === "bank_transfer" && 'active')}>
      <div className={cx("header", "cursor-pointer")} onClick={toggle}>
        <RadioInput
          control={control}
          name="paymentMethod"
          label="Chuyển khoản ngân hàng"
          value="bank_transfer"
          className="font-semibold"
        />
        <div className="max-w-[108px]">
          <img src={napasLogo} alt="napas" />
        </div>
      </div>
      <div className={cx("content", !isOpen && "hide")} ref={contentRef}>
        <div className={cx("field", "relative")}>
          <input
            placeholder="Ngân hàng"
            className={cx("input", "outline-none")}
            readOnly
          />
          <div className={cx("info-input", "max-w-[134px]")}>
            <img src={vietcombankLogo} alt="vietcombank" />
          </div>
        </div>
        <div className={cx("field", "relative")}>
          <input
            placeholder="Chủ tài khoản"
            className={cx("input", "outline-none")}
            readOnly
          />

          <div className={cx("info-input", "font-semibold")}>
            Nguyen Van Anh
          </div>
        </div>
        <div className={cx("field", "relative")}>
          <input
            placeholder="Số tài khoản"
            className={cx("input", "outline-none")}
            readOnly
          />
          <div className={cx("info-input", "font-semibold")}>
            511 957 3212 2832
          </div>
        </div>
        <p className="text-center color-desc text-[10px] leading-[13px] mt-5 mb-2">
          Sau khi chuyển khoản xong, chụp lại màn hình giao dịch thành công để
          đối chiếu thanh toán với công ty DophinX
        </p>
      </div>
    </div>
  );
}

export default memo(BankPayment);
