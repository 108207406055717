import classNames from "classnames/bind";
import { memo } from "react";

import { vietcombankLogo } from "~/assets/image/exportImage";
import BoxShadow from "~/components/BoxShadow";
import { convertToVndFormat } from "~/helper";

const paymentMethodSelectedText = {
  bank_transfer: "Chuyển khoản",
  credit_card: "Thẻ quốc tế VISA/MASTERCARD",
};

function PaymentInfo(props) {
  const { dataSurvey, pricing } = props;

  return (
    <BoxShadow className="p-[30px] mt-[30px]">
      <h3 className="text-[18px] leading-[23px] font-semibold text-center">
        Thông tin thanh toán
      </h3>
      <div className="box-info flex mt-[30px]">
        <div className="status">
          <p className="color-desc">Trạng thái</p>
          <p
            className={classNames(
              "font-semibold mt-[6px]",
              dataSurvey?.paymentStatus ? "text-primary" : "text-warning"
            )}
          >
            {dataSurvey?.paymentStatusText ||
              (dataSurvey?.paymentStatus
                ? "Thanh toán thành công"
                : "Đang chờ thành toán")}
          </p>
        </div>
        <div className="fee mx-[90px]">
          <p className="color-desc">Tổng chi phí</p>
          <p className="font-semibold mt-[6px]">
            {convertToVndFormat(pricing)} VND
          </p>
        </div>
        <div className="payment-method">
          <p className="color-desc">Hình thức thanh toán</p>
          <p className="font-semibold mt-[6px]">
            {paymentMethodSelectedText[dataSurvey?.paymentMethod]}
          </p>
        </div>
      </div>
      {dataSurvey?.paymentMethod === "bank_transfer" && (
        <div className="flex gap-3 mt-[30px]">
          <div className="flex-1 flex items-center justify-between bg-[#F5F5F5] rounded-[6px] px-3 py-4 h-[50px]">
            <p className="title-14 !font-normal">Ngân hàng</p>
            <img
              src={vietcombankLogo}
              alt="vietcombank-logo"
              className="max-w-[134px]"
            />
          </div>
          <div className="flex-1 flex items-center justify-between bg-[#F5F5F5] rounded-[6px] px-3 py-4 h-[50px]">
            <p className="title-14 !font-normal">Chủ tài khoản</p>
            <p className="title-14 text-[#595959]">Nguyen Van Anh</p>
          </div>
          <div className="flex-1 flex items-center justify-between bg-[#F5F5F5] rounded-[6px] px-3 py-4 h-[50px]">
            <p className="title-14 !font-normal">Số tài khoản</p>
            <p className="title-14 text-[#595959]">511 957 3212 2832</p>
          </div>
        </div>
      )}
    </BoxShadow>
  );
}

export default memo(PaymentInfo);
