import { memo } from "react";
import GroupAnswer from "~/components/GroupAnswer";

function ReorderChoice(props) {
  const { question, questionIndex } = props;

  return <GroupAnswer question={question} questionIndex={questionIndex} />;
}

export default memo(ReorderChoice);
