import classNames from "classnames/bind";
import { Link } from "react-router-dom";

import style from "./Button.module.scss";

const cx = classNames.bind(style);

const colorVariants = {
  infoFill: "bg-info text-white",
  infoOutline: "text-info",
  infoBorder: "border-info",
};

function Button({ children, className, ...props }) {
  const {
    iconLeft,
    iconRight,
    variant,
    bigButton,
    color,
    space,
    iconClass,
    type,
    title,
    href,
    ...restProps
  } = props;

  const Comp = type === "link" ? Link : "button";

  return (
    <Comp
      className={cx(
        `button flex items-center rounded-[4px] justify-center transition`,
        variant === "fill" &&
          `px-3 ${
            color ? colorVariants[`${color}Fill`] : "bg-primary text-white"
          } border ${
            color ? colorVariants[`${color}Border`] : "border-primary"
          }`,
        variant === "outline" &&
          `px-3 ${
            color ? colorVariants[`${color}Outline`] : " text-primary"
          } border ${
            color ? colorVariants[`${color}Border`] : "border-primary"
          }`,
        className
      )}
      {...restProps}
    >
      {iconLeft && <div className={cx(iconClass || `mr-2`)}>{iconLeft}</div>}
      {title || children}
      {iconRight && <div className={cx(iconClass || `ml-2`)}>{iconRight}</div>}
    </Comp>
  );
}

export default Button;
