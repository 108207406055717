import classNames from "classnames/bind";
import { memo } from "react";
import { useFieldArray } from "react-hook-form";

import Button from "~/components/Button";
import HeadingBig from "~/components/HeadingBig";
import TextArea from "~/components/TextArea";
import style from "../../Segment.module.scss";
import { useSurveyStore } from "~/store/SurveyContext";
import Tooltip from "~/components/Tooltip";

const cx = classNames.bind(style);

function OtherBehavior(props) {
  const { control, otherBehaviorText, setValue } = props;
  const {
    dataSurvey: { onlyView },
  } = useSurveyStore();
  const { append, remove, fields } = useFieldArray({
    name: "otherBehavior",
    control: control,
  });

  return (
    <div className={cx("section")}>
      <HeadingBig
        className={cx("heading")}
        title="Tự điền yêu cầu nhân khẩu học / hành vi khác"
      />
      <div className="flex">
        <div className="flex-1 mr-[30px]">
          <p className="text-[#b3b3b3]">
            Bạn có thể thêm các yêu cầu đặc biệt cho các đối tượng nghiên cứu
            nếu như các tiêu chí nhân khẩu học phía trên chưa có. Các yêu cầu
            đối tượng có thể bao gồm bất kỳ tiêu chí nhân khẩu học, hành vi, sở
            thích nào. VD: “đã sử dụng sản phẩm X trong vòng 3 tuần qua”, “nhãn
            hiệu mỳ gói yêu thích nhất là B”,...
          </p>
        </div>
        <div className="flex-1">
          <p className="text-[#b3b3b3]">
            DophinX sẽ xử lý các yêu cầu này trong 24 tiếng và liên lạc để thống
            nhất với bạn bảng câu hỏi cuối cùng, cũng như thông tin chi phí và
            thời gian hoàn thành nghiên cứu.
          </p>
        </div>
      </div>
      <div className="flex mt-5">
        <div className="flex-1 mr-[30px]">
          <h3 className="title-14">Thêm yêu cầu</h3>
          <TextArea
            control={control}
            name="otherBehaviorText"
            placeholder="Điền câu trả lời"
            className="my-3 h-[76px] rounded-[6px] overflow-hidden border border-[#E6E6E6] p-3"
            inputProps={{
              disabled: onlyView,
            }}
          />
          <Button
            variant="fill"
            className="py-3 px-5 !rounded-full border border-primary hover:brightness-90 transition"
            iconLeft={
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  className="fill-current"
                  d="M6.00008 0.666992C6.49515 0.666992 6.89648 1.06832 6.89648 1.56339L6.89648 5.10393L10.437 5.10393C10.9321 5.10393 11.3334 5.50526 11.3334 6.00033C11.3334 6.49539 10.9321 6.89672 10.437 6.89672H6.89648L6.89648 10.4373C6.89648 10.9323 6.49515 11.3337 6.00008 11.3337C5.50501 11.3337 5.10368 10.9323 5.10368 10.4373L5.10369 6.89672H1.56314C1.06808 6.89672 0.666747 6.49539 0.666748 6.00033C0.666748 5.50526 1.06808 5.10393 1.56314 5.10393L5.10368 5.10393L5.10369 1.56339C5.10368 1.06832 5.50501 0.666992 6.00008 0.666992Z"
                  fill="white"
                />
              </svg>
            }
            onClick={() => {
              if (!otherBehaviorText) return;

              append({
                id: fields.length + 1,
                value: otherBehaviorText,
              });

              setValue("otherBehaviorText", "");
            }}
          >
            Thêm
          </Button>
        </div>
        <div className="flex-1">
          <h3 className="title-14">Các yêu cầu được thêm</h3>
          <ul className="mt-3">
            {fields.map((field, i) => (
              <li key={i} className="first:mt-0 mt-3">
                <Button
                  variant="outline"
                  className="flex items-center justify-between !rounded-full py-3 px-6 font-semibold w-full text-left"
                  iconRight={
                    <div
                      className="block h-fit w-3"
                      onClick={() => {
                        remove(i);
                      }}
                    >
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          className="fill-current"
                          d="M9.95948 0.625902C10.35 0.235377 10.9832 0.235377 11.3737 0.625902C11.7642 1.01643 11.7642 1.64959 11.3737 2.04012L7.41413 5.99967L11.3737 9.95923C11.7642 10.3498 11.7642 10.9829 11.3737 11.3734C10.9832 11.764 10.35 11.764 9.95948 11.3734L5.99992 7.41389L2.04036 11.3734C1.64983 11.764 1.01667 11.764 0.626145 11.3734C0.235621 10.9829 0.235621 10.3498 0.626145 9.95923L4.5857 5.99967L0.626145 2.04012C0.235621 1.64959 0.235621 1.01643 0.626145 0.625902C1.01667 0.235378 1.64983 0.235378 2.04036 0.625902L5.99992 4.58546L9.95948 0.625902Z"
                          fill="#00D68F"
                        />
                      </svg>
                    </div>
                  }
                >
                  <Tooltip text={field.value} classNameBox="mt-2">
                    <span className="limit-1-row">{field.value}</span>
                  </Tooltip>
                </Button>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default memo(OtherBehavior);
