import classNames from "classnames/bind";
import { memo, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import Input from "~/components/Input";
import Checkbox from "~/components/Checkbox";
import HeadingBig from "~/components/HeadingBig";
import Button from "~/components/Button";
import { axiosClient } from "~/api/axiosClient";
import style from "./Register.module.scss";

const cx = classNames.bind(style);

function Register(props) {
  const methods = useForm({
    mode: "onSubmit",
    defaultValues: {
      name: "",
      email: "",
      password: "",
      confirmPassword: "",
      check: false,
    },
    resolver: yupResolver(
      yup.object({
        name: yup.string().required("Họ và tên không được để trống!"),
        email: yup
          .string()
          .email("Email chưa nhập đúng định dạng")
          .required("Email không được để trống!"),
        password: yup
          .string()
          .min(8, "Mật khẩu tối thiểu 8 ký tự")
          .required("Mật khẩu không được để trống!"),
        confirmPassword: yup
          .string()
          .oneOf(
            [yup.ref("password")],
            "Nhập lại mật khẩu không giống mật khẩu"
          ),
        check: yup.boolean().oneOf([true], "Chưa đồng ý các điều khoản"),
      })
    ),
  });
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = methods;
  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordConfirmShown, setPasswordConfirmShown] = useState(false);
  const [registerStatus, setRegisterStatus] = useState(false);
  const [error, setError] = useState(false);
  const formHasError = Object.keys(errors).length > 0

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };
  const togglePasswordConfirm = () => {
    setPasswordConfirmShown(!passwordConfirmShown);
  };

  const submit = (data) => {
    (async () => {
      const dataSubmit = {
        name: data.name,
        email: data.email,
        password: data.password,
        confirmPassword: data.confirmPassword,
      };
      return axiosClient
        .post("/auth/member/register", dataSubmit)
        .then(() => {
          setRegisterStatus(true);
        })
        .catch((err) => {
          setError(err.response.data.message);
        });
    })();
  };
  if (registerStatus === false) {
    return (
      <div className={cx("content-form w-[332px] form")}>
        <HeadingBig className={cx("heading field")} title="Tạo tài khoản mới" />
        <div className="relative">
          <img
            src={require("~/assets/image/account.png")}
            alt="icon"
            className="absolute top-[14px] left-[15px]"
          />
          <Input
            control={control}
            placeholder="Họ và tên"
            name="name"
            inputProps={{
              className: cx("input-field", "!mt-[30px]"),
            }}
          />
        </div>

        <div className="relative">
          <img
            src={require("~/assets/image/message.png")}
            alt="icon"
            className="absolute top-[14px] left-[15px]"
          />
          <Input
            control={control}
            placeholder="Nhập email"
            name="email"
            inputProps={{
              className: cx("input-field", "!mt-[20px]"),
            }}
          />
        </div>

        <div className="relative">
          <img
            src={require("~/assets/image/lock.png")}
            alt="icon"
            className="absolute top-[14px] left-[15px]"
          />
          <Input
            control={control}
            placeholder="Nhập mật khẩu"
            type={passwordShown ? "" : "password"}
            name="password"
            inputProps={{
              className: cx("input-field", "!mt-[20px]"),
            }}
          />
          <img
            src={require("~/assets/image/show.png")}
            alt="icon"
            onClick={togglePassword}
            className="absolute top-[14px] right-[15px]"
          />
        </div>

        <div className="relative">
          <img
            src={require("~/assets/image/lock.png")}
            alt="icon"
            className="absolute top-[14px] left-[15px]"
          />
          <Input
            control={control}
            placeholder="Nhập lại mật khẩu"
            type={passwordConfirmShown ? "" : "password"}
            name="confirmPassword"
            inputProps={{
              className: cx("input-field", "!mt-[20px]"),
            }}
          />
          <img
            src={require("~/assets/image/show.png")}
            alt="icon"
            onClick={togglePasswordConfirm}
            className="absolute top-[14px] right-[15px]"
          />
        </div>
        <Checkbox
          control={control}
          className="cursor-default mt-[20px]"
          name="check"
          value="true"
          label={
            <div className="text-[12px]">
              Tôi đồng ý với các{" "}
              <a className="text-[#1A8CFF]" href="#">
                Điều khoản và Điều kiện
              </a>
            </div>
          }
        />

        {error ? (
          <div className="box-error flex justify-center items-center mt-[20px]">
            <img src={require("~/assets/image/information.png")} alt="" />
            {error}
          </div>
        ) : (
          ""
        )}

        <div className="flex justify-center mt-[30px]">
          <Button
            className={cx(
              "px-[42px] py-[12px] rounded-[66px]",
              formHasError ? "bg-[#CCCCCC]" : "bg-primary text-white"
            )}
            onClick={handleSubmit(submit)}
          >
            Tạo tài khoản
          </Button>
        </div>
        <div className="mt-[30px] text-[12px] text-center">
          Bạn đã có tài khoản?{" "}
          <a href="/login" className="text-[#1A8CFF]">
            Đăng nhập
          </a>
        </div>
      </div>
    );
  }
  if (registerStatus === true) {
    return (
      <div className={cx("content-form w-[332px] form")}>
        <p className="text-center text-[18px] leading-[130%] font-semibold">
          Yahhhh! <br />
          Bạn đã tạo tài khoản thành công
        </p>
        <br />
        <p className="text-center text-[18px] leading-[130%] font-semibold">
          Mời bạn tạo workspace để bắt đầu trải nghiệm DophinX nhé!
        </p>
        <div className="flex justify-center mt-[30px]">
          <Button
            className="bg-[#00D68F] px-[42px] py-[12px] rounded-[66px] 
                    text-[12px] text-[#FFFFFF] font-semibold"
          >
            Tạo workspace
          </Button>
        </div>
      </div>
    );
  }
}

export default memo(Register);
