import { useQuery } from "@tanstack/react-query";
import classNames from "classnames/bind";
import { useMemo } from "react";
import { useForm } from "react-hook-form";

import Label from "~/assets/icon/Label";
import Button from "~/components/Button";
import OptionsFooter from "./OptionsFooter";
import SectionItem from "./SectionItem";
import style from "./SidebarLeft.module.scss";
import SidebarSection from "./SidebarSection";
import { useGlobalState } from "~/store/GlobalContext";
import { surveyService } from "~/services/surveyService";
import SelectDropdown from "~/components/SelectDropdown";

const cx = classNames.bind(style);

const options = [
  {
    value: 1,
    label: "Leanne Graham",
  },
];

export const researchInfoItem = [
  { name: "Đang diễn ra", classLabel: "text-info" },
  { name: "Hoàn thành", classLabel: "text-primary" },
  { name: "Đang chờ duyệt", classLabel: "text-[#999999]" },
];

function SidebarLeft() {
  const userId = localStorage.getItem("userId");
  const {
    globalState: { menuCollapse },
  } = useGlobalState();
  const { data } = useQuery({
    queryKey: ["survey-count"],
    queryFn: async () =>
      await surveyService.list("/count", {
        memberId: userId,
      }),
  });

  const { control } = useForm({
    defaultValues: {
      workspace: [],
    },
  });

  const dataCount = useMemo(() => {
    const queryData = data?.data;

    return queryData;
  }, [data]);

  const handleShowLabelColor = (name) => {
    const typeLabel = researchInfoItem.find((item) => item.name === name);
    if (!typeLabel) return;

    return typeLabel?.classLabel;
  };

  return (
    <div
      className={cx(
        "wrapper",
        "max-w-[240px] w-full h-auto flex flex-col bg-white px-5 transition-all",
        menuCollapse && "max-w-[64px] !px-3"
      )}
    >
      <div className="py-5">
        <SelectDropdown
          control={control}
          name="workspace"
          options={options}
          onChange={(value) => console.log(value)}
          color="#00D68F"
          className={`select-dropdown bg-white text-[#333] text-[12px] leading-[16px] font-semibold !min-h-[48px] !px-3 rounded-[4px] border-[#E6E6E6] ${
            menuCollapse && "menu-collapse !px-0 justify-center"
          }`}
        />
        <Button
          variant="fill"
          space="6"
          className={cx(
            "mt-[20px] w-full text-[12px] leading-[16px] font-semibold min-h-[48px] transition border border-primary hover:brightness-[0.95]",
            menuCollapse && "px-0"
          )}
          onClick={() => {
            window.location.href = "/create-survey";
          }}
        >
          <svg
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={cx("mr-2 w-5", menuCollapse && "mr-0")}
          >
            <path
              className="fill-current"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4.31282 3.81282C7.7299 0.395728 13.2701 0.395728 16.6872 3.81282C20.1043 7.2299 20.1043 12.7701 16.6872 16.1872C13.2701 19.6043 7.7299 19.6043 4.31282 16.1872C0.895728 12.7701 0.895728 7.2299 4.31282 3.81282ZM15.4497 5.05025C12.7161 2.31658 8.28392 2.31658 5.55025 5.05025C2.81658 7.78392 2.81658 12.2161 5.55025 14.9497C8.28392 17.6834 12.7161 17.6834 15.4497 14.9497C18.1834 12.2161 18.1834 7.78392 15.4497 5.05025Z"
              fill="white"
            />
            <path
              className="fill-current"
              d="M10.5 5.32543C10.9339 5.32543 11.2857 5.67719 11.2857 6.11111L11.2857 9.21432L14.3889 9.21432C14.8228 9.21432 15.1746 9.56608 15.1746 10C15.1746 10.4339 14.8228 10.7857 14.3889 10.7857H11.2857L11.2857 13.8889C11.2857 14.3228 10.9339 14.6746 10.5 14.6746C10.0661 14.6746 9.71433 14.3228 9.71432 13.8889L9.71433 10.7857H6.61111C6.17719 10.7857 5.82543 10.4339 5.82544 10C5.82544 9.56608 6.17719 9.21432 6.61111 9.21432L9.71432 9.21432L9.71433 6.11111C9.71433 5.67719 10.0661 5.32543 10.5 5.32543Z"
              fill="white"
            />
          </svg>
          <span className={cx(menuCollapse && "hidden")}>Tạo nghiên cứu</span>
        </Button>
      </div>

      <div className="mt-[14px]">
        <SidebarSection title="Nghiên cứu của tôi">
          {researchInfoItem.map((item, i) => {
            const typeInfo =
              dataCount && dataCount.find((type) => type.name === item.name);

            return (
              <SectionItem
                key={i}
                title={item.name}
                surveyNumber={typeInfo?.total}
                icon={<Label className={handleShowLabelColor(item?.name)} />}
                type={typeInfo?.type}
              />
            );
          })}
        </SidebarSection>
      </div>
      <OptionsFooter
        className={cx("mt-auto mx-[-20px]", menuCollapse && "-mx-3")}
      />
    </div>
  );
}

export default SidebarLeft;
