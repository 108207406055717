export const typeQuestions = ['single_choice', 'multi_choice', 'free_text', 'yes_no', 'level_choice', 'reorder_choice', 'images_choice'];

const singleChoice = () => ({
  title: 'Câu hỏi single choice',
  type: 'single_choice',
  options: ['Option 1', 'Option 2'],
})

const multiChoice = () => ({
  title: 'Câu hỏi multi choice',
  type: 'multi_choice',
  options: ['Option 1', 'Option 2'],
})

const freeText = () => ({
  title: "Câu hỏi free text",
  type: "free_text",
})

const yesNo = () => ({
  title: "Câu hỏi yes/no",
  type: "yes_no",
})

const reorderChoice = () => ({
  title: "Câu hỏi reorder choice",
  type: "reorder_choice",
  options: ['Option 1', 'Option 2'],
})

const imagesChoice = () => ({
  title: "Câu hỏi image choice",
  type: "images_choice",
  options: [],
})

const levelChoice = () => ({
  title: "Câu hỏi level choice",
  type: "level_choice",
  options: [
    {
      title: "",
      value: 1
    },
    {
      title: "",
      value: 5
    }
  ]
})

const questionTypeDataTemplate = {
  singleChoice, freeText, multiChoice, yesNo, levelChoice, reorderChoice, imagesChoice
}

export default questionTypeDataTemplate
export {
  singleChoice, freeText
}

/*
  Create object with type name question convert
  Ex: {single_choice: singleChoice,, ...}
*/

export const typeQuestionsNewData = typeQuestions.reduce((acc, current) => {
  let [firstWord, ...otherWords] = current.split('_');
  otherWords = otherWords.map(word => {
    let [firstLetter, ...rest] = word
    return [firstLetter.toUpperCase(), ...rest].join('')
  })

  const nameMethodTemplate = `${firstWord}${otherWords.join('')}`;
  return {
    ...acc,
    [current]: nameMethodTemplate
  }
}, {})