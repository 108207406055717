/* eslint-disable react-hooks/exhaustive-deps */
import classNames from "classnames/bind";
import { useEffect, useMemo } from "react";
import { useState } from "react";
import { memo } from "react";
import { useParams } from "react-router-dom";

import BoxShadow from "~/components/BoxShadow";
import ExportFileTab from "./components/ExportFileTab";
import ProgressTab from "./components/ProgressTab";
import ResultTab from "./components/ResultTab";
import style from "./SurveyResult.module.scss";
import { useSurveyStore } from "~/store/SurveyContext";
import { setOnlyView, setSurveyDataOnlyView } from "~/store/actions/SurveyActions";
import { useQuery } from "@tanstack/react-query";
import { surveyService } from "~/services/surveyService";

const cx = classNames.bind(style);

const nameTabs = [
  "Tiến độ nghiên cứu",
  "Kết quả nghiên cứu",
  "Xuất file kết quả",
];

const componentTabs = [ProgressTab, ResultTab, ExportFileTab];

function SurveyResult(props) {
  const { surveyId } = useParams();
  const {
    dispatch,
    dataSurvey: { onlyView },
    setStepOpen,
  } = useSurveyStore();

  const {data: surveyDataOnlyView} = useQuery({
    queryKey: [`survey-${surveyId}`],
    queryFn: async () => await surveyService.list(`/${surveyId}`),
    enabled: !!surveyId
  })


  useEffect(() => {
    if (surveyId && surveyDataOnlyView) {
      dispatch(setOnlyView(surveyId));
      dispatch(setSurveyDataOnlyView(surveyDataOnlyView?.data))
    }
  }, [surveyDataOnlyView]);

  useEffect(() => {
    if (onlyView) {
      setStepOpen("all");
    }
  }, [onlyView]);

  const [tabActive, setTabActive] = useState(0);
  const ComponentActive = useMemo(() => {
    return componentTabs[tabActive];
  }, [tabActive]);

  return (
    <div className={cx("wrapper", "max-w-[924px] w-full mx-auto")}>
      <BoxShadow className="pt-[30px] flex justify-center text-[#CCCCCC] mb-5">
        {nameTabs.map((tab, i) => (
          <div
            key={tab}
            className={cx(
              i !== 0 && "ml-[60px]",
              "text-[14px] leading-[18px] pb-5 relative cursor-pointer",
              i === tabActive &&
                "text-primary font-semibold after:w-full after:h-1 after:block after:absolute after:bottom-0 after:bg-primary"
            )}
            onClick={() => setTabActive(i)}
          >
            {tab}
          </div>
        ))}
      </BoxShadow>
      <ComponentActive className="mt-5" />
    </div>
  );
}

export default memo(SurveyResult);
