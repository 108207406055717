import { memo } from "react";
import { useFormContext } from "react-hook-form";
import { convertToNameTypeQuestion } from "~/helper";

import QuestionItem from "../QuestionItem";

function QuestionSidebarLeft(props) {
  const { questionPicking, setQuestionPicking } = props;
  const { watch } = useFormContext();
  const questions = watch("questions");

  return (
    <div className="max-w-[216px] w-full h-fit p-[20px_16px_12px] rounded-[10px] shadow-[0px_0px_8px_rgba(0,0,0,0.08)] bg-white sticky top-0">
      <p className="title-14">Danh mục câu hỏi</p>
      <ul className="mt-[10px]">
        {questions.map((question, i) => (
          <QuestionItem
            key={i}
            index={i + 1}
            question={convertToNameTypeQuestion(question.type)}
            active={i === questionPicking}
            onClick={() => setQuestionPicking(i)}
          />
        ))}
      </ul>
    </div>
  );
}

export default memo(QuestionSidebarLeft);
