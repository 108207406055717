/* eslint-disable react-hooks/exhaustive-deps */
import classNames from "classnames/bind";
import { memo } from "react";
import { useReducer } from "react";
import { useForm } from "react-hook-form";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import AdvancedInfo from "./components/AdvancedInfo";
import BasicInfo from "./components/BasicInfo";
import FooterNavigationButtons from "../../../components/FooterNavigationButtons";
import OtherBehavior from "./components/OtherBehavior";
import ResearchQuantity from "./components/ResearchQuantity";
import style from "./Segment.module.scss";
import objectInfoReducer from "~/store/reducer/objectInfoReducer";
import { useSurveyStore } from "~/store/SurveyContext";
import { addDataSurvey } from "~/store/actions/SurveyActions";

const cx = classNames.bind(style);

function Segment() {
  const {
    dispatch: dispatchSurvey,
    dataSurvey: { targetNumber, segment, onlyView },
    setStepOpen,
  } = useSurveyStore();

  const [data, dispatch] = useReducer(objectInfoReducer, segment);
  const navigate = useNavigate();
  const { control, watch, setValue } = useForm({
    defaultValues: {
      targetNumber: targetNumber,
      otherBehavior: [],
      otherBehaviorText: "",
    },
  });

  const handleSubmit = useCallback(() => {
    const targetNumber = watch("targetNumber").replace(/\./g, "");

    dispatchSurvey(
      addDataSurvey({
        segment: data,
        targetNumber,
      })
    );
    navigate("/create-questions");
    setStepOpen((prev) => prev + 1);
  }, [data]);

  return (
    <div className={cx("wrapper", "max-w-[924px] mx-auto mb-5")}>
      <BasicInfo dispatch={dispatch} />
      <AdvancedInfo dispatch={dispatch} />
      <OtherBehavior
        control={control}
        otherBehaviorText={watch("otherBehaviorText")}
        setValue={setValue}
      />
      <ResearchQuantity
        control={control}
        targetNumber={watch("targetNumber")}
      />
      {!onlyView && <FooterNavigationButtons
        buttonLeft={{
          title: "Quay về",
          iconLeft: (
            <svg
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.9754 16.3968C13.6093 16.7571 13.0157 16.7571 12.6496 16.3968L7.02459 10.861C6.65847 10.5007 6.65847 9.91657 7.02459 9.55627L12.6496 4.02055C13.0157 3.66025 13.6093 3.66025 13.9754 4.02055C14.3415 4.38086 14.3415 4.96503 13.9754 5.32534L9.01332 10.2087L13.9754 15.092C14.3415 15.4523 14.3415 16.0365 13.9754 16.3968Z"
                fill="#00D68F"
              />
            </svg>
          ),
          type: "link",
          to: "/create-survey",
        }}
        buttonRight={{
          title: "Hoàn thành & tạo bảng câu hỏi",
          onClick() {
            handleSubmit();
          },
        }}
      />}
    </div>
  );
}

export default memo(Segment);
