import { useContext } from "react"
import { createContext } from "react"

const SurveyContext = createContext()

const useSurveyStore = () => {
  return useContext(SurveyContext)
}

export default SurveyContext
export { useSurveyStore }