import { memo } from "react";

function DeleteImage({ className }) {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_625_21730)">
        <g filter="url(#filter0_d_625_21730)">
          <path
            d="M11.9592 2.62639C12.3498 2.23586 12.9829 2.23586 13.3734 2.62639C13.764 3.01691 13.764 3.65008 13.3734 4.0406L9.41389 8.00016L13.3734 11.9597C13.764 12.3502 13.764 12.9834 13.3734 13.3739C12.9829 13.7645 12.3498 13.7645 11.9592 13.3739L7.99967 9.41438L4.04011 13.3739C3.64959 13.7645 3.01643 13.7645 2.6259 13.3739C2.23538 12.9834 2.23538 12.3502 2.6259 11.9597L6.58546 8.00016L2.6259 4.0406C2.23538 3.65008 2.23538 3.01691 2.6259 2.62639C3.01643 2.23587 3.64959 2.23587 4.04011 2.62639L7.99967 6.58595L11.9592 2.62639Z"
            fill="#B3B3B3"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_625_21730"
          x="-5.66699"
          y="-5.6665"
          width="27.333"
          height="27.3335"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_625_21730"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_625_21730"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_625_21730">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default memo(DeleteImage);
