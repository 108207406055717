import { memo } from "react";
import Button from "~/components/Button";

function SelectedBar(props) {
  const { title, selection, handleRemoveAllItem } = props;

  return (
    <div className="bg-primary rounded-full flex justify-between items-center py-[10px] px-3 mt-3">
      <div className="left flex items-center flex-1">
        <svg
          className="w-[12px] h-[12px]"
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7 12.4167C9.99154 12.4167 12.4167 9.99154 12.4167 7C12.4167 4.00846 9.99154 1.58333 7 1.58333C4.00846 1.58333 1.58333 4.00846 1.58333 7C1.58333 9.99154 4.00846 12.4167 7 12.4167ZM7 13.25C10.4518 13.25 13.25 10.4518 13.25 7C13.25 3.54822 10.4518 0.75 7 0.75C3.54822 0.75 0.75 3.54822 0.75 7C0.75 10.4518 3.54822 13.25 7 13.25Z"
            fill="white"
          />
          <path
            d="M10.3333 7C10.3333 8.84095 8.84095 10.3333 7 10.3333C5.15905 10.3333 3.66667 8.84095 3.66667 7C3.66667 5.15905 5.15905 3.66667 7 3.66667C8.84095 3.66667 10.3333 5.15905 10.3333 7Z"
            fill="white"
          />
        </svg>
        <p className="text-white font-semibold ml-[10px] limit-1-row flex-1" title={`${title}: ${selection}`}>
          {title}: {selection}
        </p>
      </div>
      <Button className="right w-[11px]" onClick={handleRemoveAllItem}>
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.95948 0.62639C10.35 0.235865 10.9832 0.235865 11.3737 0.62639C11.7642 1.01691 11.7642 1.65008 11.3737 2.0406L7.41413 6.00016L11.3737 9.95972C11.7642 10.3502 11.7642 10.9834 11.3737 11.3739C10.9832 11.7645 10.35 11.7645 9.95948 11.3739L5.99992 7.41438L2.04036 11.3739C1.64983 11.7645 1.01667 11.7645 0.626145 11.3739C0.235621 10.9834 0.235621 10.3502 0.626145 9.95972L4.5857 6.00016L0.626145 2.0406C0.235621 1.65008 0.235621 1.01691 0.626145 0.62639C1.01667 0.235866 1.64983 0.235866 2.04036 0.62639L5.99992 4.58595L9.95948 0.62639Z"
            fill="white"
          />
        </svg>
      </Button>
    </div>
  );
}

export default memo(SelectedBar);
