import classNames from "classnames/bind";
import { useRef } from "react";

import BackToTop from "~/assets/icon/BackToTop";
import Button from "~/components/Button";
import useBackToTop from "~/hooks/useBackToTop";
import Header from "../components/Header";
import SidebarLeft from "../components/SidebarLeft";

import style from "./DefaultLayout.module.scss";

const cx = classNames.bind(style);

function DefaultLayout({ children }) {
  const { contentScroll, handleBackToTop } = useBackToTop();

  return (
    <div className="wrapper flex flex-col h-full relative">
      <Header />
      <div className="flex flex-1 bg-[#F5F5F5]">
        <SidebarLeft />
        <div
          className={cx("main-content", "flex-1 relative overflow-auto")}
          ref={contentScroll}
        >
          <main className="absolute inset-0">{children}</main>
        </div>
      </div>
      <Button
        className="absolute bottom-5 right-5 text-[#999999] hover:text-primary"
        onClick={handleBackToTop}
      >
        <BackToTop />
      </Button>
    </div>
  );
}

export default DefaultLayout;
