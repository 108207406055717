import classNames from "classnames/bind";
import { memo } from "react";
import Checkbox from "../Checkbox";

function CheckboxGroup(props) {
  const { options, name, className, columns, control, disabled } = props;

  return (
    <div
      className={classNames("grid", className)}
      style={{
        gridTemplateColumns: `repeat(${columns}, 1fr)`,
      }}
    >
      {options.map((option, i) => (
        <Checkbox
          key={i}
          name={name}
          value={option.value}
          label={option.title || option.label}
          control={control}
          checkboxGroup
          inputProps={{ disabled }}
        />
      ))}
    </div>
  );
}

export default memo(CheckboxGroup);
