/* eslint-disable no-useless-constructor */
import baseService from "~/api/BaseService";

class AuthMember extends baseService {
  BASE_ENDPOINT = '/auth/member'

  constructor(props) {
    super(props)
  }
}

export const authMemberService = new AuthMember()