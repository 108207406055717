/* eslint-disable react-hooks/exhaustive-deps */
import classNames from "classnames/bind";
import { memo } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useState } from "react";
import { toast } from "react-toastify";
import { useMutation } from "@tanstack/react-query";
import { useEffect } from "react";

import ArrowBot from "~/assets/icon/ArrowBot";
import Edit from "~/assets/icon/Edit";
import Photo from "~/assets/icon/Photo";
import Play from "~/assets/icon/Play";
import Question from "~/assets/icon/Question";
import BoxShadow from "~/components/BoxShadow";
import Button from "~/components/Button";
import BoxSelectTypeQuestion from "../BoxSelectTypeQuestion";
import style from "./QuestionDetailWrapper.module.scss";
import TextArea from "~/components/TextArea";
import Input from "~/components/Input";
import { useSurveyStore } from "~/store/SurveyContext";
import { uploadFileService } from "~/services/uploadFileService";

const cx = classNames.bind(style);

function QuestionDetailWrapper(props) {
  const {
    dataSurvey: { onlyView },
  } = useSurveyStore();
  const { children, className, questionIndex, question } = props;
  const { title = "", image = "", description = "" } = question;
  const { control, update } = useFormContext();
  const questions = useWatch({
    name: "questions",
    control,
  });

  // Have a description in question?!
  const isIncludeNote = questions[questionIndex]?.hasOwnProperty("description");
  const [showDescription, setShowDescription] = useState(isIncludeNote);

  const handleAddNote = () => {
    setShowDescription(!showDescription);

    if (isIncludeNote) return;
    update(questionIndex, {
      ...questions[questionIndex],
      description: "",
    });
  };

  const { mutate } = useMutation({
    mutationFn: async (file) =>
      await uploadFileService.create(
        "",
        { file },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      ),
    onSuccess(data) {
      const {
        data: { Location },
      } = data;

      update(questionIndex, {
        ...questions[questionIndex],
        image: Location,
      });
    },
  });

  // useEffect(() => {
  //   if (data) {
  //     const {
  //       data: { Location },
  //     } = data;

  //     update(questionIndex, {
  //       ...question,
  //       image: Location,
  //     });
  //   }
  // }, [isSuccess]);

  const handleAddAvatar = async (e) => {
    const file = e.target.files[0];

    if (!file.type.includes("image/")) {
      toast.error("File tải lên phải là một ảnh!");
      return;
    }

    mutate(file);
  };

  return (
    <BoxShadow className={classNames("p-5", className)}>
      <div className="flex justify-between items-center">
        <h3 className="title-14">Câu hỏi {questionIndex + 1}</h3>
        <div className="question-feature flex">
          <Button className={cx("button")}>
            <Photo className={cx("icon")} />
            Thêm hình
            <input
              type="file"
              className="absolute opacity-0 inset-0 cursor-pointer"
              onChange={handleAddAvatar}
              accept="image/*"
              disabled={onlyView}
            />
          </Button>
          <Button className={cx("button")} disabled={onlyView}>
            <Play className={cx("icon")} />
            Thêm video
          </Button>
          <Button
            className={cx("button")}
            disabled={onlyView}
            onClick={handleAddNote}
          >
            <Edit className={cx("icon")} />
            Thêm chú thích
          </Button>
          <div className={cx("choose-type", "relative")}>
            <Button className={cx("button")} disabled={onlyView}>
              <Question className={cx("icon")} />
              Loại câu hỏi
              <ArrowBot className="ml-2 w-[18px] h-[18px]" />
            </Button>
            <BoxSelectTypeQuestion
              className={cx(
                "modal",
                "min-w-[175px] absolute top-full right-0 p-3 pr-5 mt-3 text-[#999999] w-fit transition opacity-0 pointer-events-none z-10"
              )}
              questionIndex={questionIndex}
              question={question}
            />
          </div>
        </div>
      </div>
      <div>
        {image && (
          <img
            src={image}
            alt={`avatar-${questionIndex + 1}`}
            className="aspect-[648/340] rounded-[12px] object-cover mt-3 w-full"
          />
        )}
        <TextArea
          control={control}
          name={`questions.${questionIndex}.title`}
          value={title}
          className="bg-[#F5F5F5] rounded-[6px] py-5 px-3 my-3 title-14 !font-normal"
          inputProps={{
            disabled: onlyView,
          }}
        />
        {(showDescription || description) && (
          <Input
            control={control}
            name={`questions.${questionIndex}.description`}
            className="block min-h-[36px] w-full border-b border-[#E6E6E6] placeholder:text-[#B3B3B3] outline-none my-3 px-3"
            placeholder="Phần chú thích"
            inputProps={{
              disabled: onlyView,
            }}
          />
        )}
        {children}
      </div>
    </BoxShadow>
  );
}

export default memo(QuestionDetailWrapper);
