function Question({ className }) {
  return (
    <svg
      className={className}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9686 1.5C14.1567 1.5 15.375 2.835 15.375 5.1225V12.87C15.375 15.195 14.1567 16.5 11.9686 16.5H6.03208C3.87875 16.5 2.625 15.195 2.625 12.87V5.1225C2.625 2.835 3.87875 1.5 6.03208 1.5H11.9686ZM6.22333 11.805C6.01083 11.7825 5.80542 11.8875 5.69208 12.0825C5.57875 12.27 5.57875 12.5175 5.69208 12.7125C5.80542 12.9 6.01083 13.0125 6.22333 12.9825H11.7767C12.0593 12.9525 12.2725 12.6967 12.2725 12.3975C12.2725 12.09 12.0593 11.835 11.7767 11.805H6.22333ZM11.7767 8.38425H6.22333C5.91804 8.38425 5.67083 8.6475 5.67083 8.97C5.67083 9.2925 5.91804 9.555 6.22333 9.555H11.7767C12.0813 9.555 12.3292 9.2925 12.3292 8.97C12.3292 8.6475 12.0813 8.38425 11.7767 8.38425ZM8.34054 4.9875H6.22333V4.995C5.91804 4.995 5.67083 5.2575 5.67083 5.58C5.67083 5.9025 5.91804 6.165 6.22333 6.165H8.34054C8.64583 6.165 8.89375 5.9025 8.89375 5.57175C8.89375 5.25 8.64583 4.9875 8.34054 4.9875Z"
        fill="#999999"
      />
    </svg>
  );
}

export default Question;
