import classNames from "classnames/bind";
import { memo } from "react";

import style from "./OptionsFooter.module.scss";
import { useGlobalState } from "~/store/GlobalContext";

const cx = classNames.bind(style);

function OptionItem({ title, icon }) {
  const {
    globalState: { menuCollapse },
  } = useGlobalState();

  return (
    <div className={cx("flex items-center px-[22px] py-[20px] w-full min-h-[58px] border-[#E6E6E6] border-t cursor-pointer", menuCollapse && "!px-5")}>
      <div className={cx("icon", "flex items-center justify-center")}>
        {icon}
      </div>
      <p className={cx("title", "ml-[22px]", menuCollapse && "hidden")}>
        {title}
      </p>
    </div>
  );
}

export default memo(OptionItem);
