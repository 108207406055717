import classNames from "classnames/bind";
import { memo, useMemo } from "react";
import BoxShadow from "~/components/BoxShadow";
import Button from "~/components/Button";

import style from "../../FinalPreview.module.scss";

const cx = classNames.bind(style);

function InfoResearch(props) {
  const { className, dataSurvey } = props;

  const fields = useMemo(
    () => [
      { label: "Tên nghiên cứu", value: dataSurvey?.name },
      { label: "Mục tiêu nghiên cứu", value: dataSurvey?.type },
      { label: "Thông tin chi tiết", value: dataSurvey?.description },
      { label: "Số lượng mẫu nghiên cứu", value: dataSurvey?.targetNumber },
    ],
    [dataSurvey]
  );

  return (
    <BoxShadow className={cx("flex-1 px-[30px] py-5", className)}>
      <h3 className="text-[16px] leading-[21px] font-semibold">Thông tin</h3>
      <div className="mt-5">
        {fields.map(({ label, value }) => (
          <div className={cx("field")} key={label}>
            <p className="color-desc">{label}</p>
            <p className="font-semibold mt-[6px]">{value}</p>
          </div>
        ))}
      </div>
      <Button type='link' to='/create-survey' className="text-info mx-auto mt-auto">Xem toàn bộ</Button>
    </BoxShadow>
  );
}

export default memo(InfoResearch);
