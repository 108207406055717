import { memo } from "react";

function UserCircle(props) {
  const { className, active } = props;

  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM12 13.875C9.35293 13.875 7.03534 15.2441 5.75 17.2937C7.97365 19.7325 10.0746 20.125 12 20.125C13.9254 20.125 16.0264 19.4159 18.25 17.2937C16.9647 15.2441 14.6471 13.875 12 13.875ZM12 12.625C13.7259 12.625 15.125 11.2259 15.125 9.5C15.125 7.77411 13.7259 6.375 12 6.375C10.2741 6.375 8.875 7.77411 8.875 9.5C8.875 11.2259 10.2741 12.625 12 12.625Z"
        fill={active ? "" : "#595959"}
      />
    </svg>
  );
}

export default memo(UserCircle);
