import SurveyInfo from '~/Layout/SurveyInfo'
import Dashboard from '~/pages/Dashboard'
import InitSurvey from '~/pages/Survey/InitSurvey'
import Segment from '~/pages/Survey/Segment'
import QuestionList from '~/pages/Survey/QuestionList'
import FinalPreview from '~/pages/Survey/FinalPreview'
import SurveyResult from '~/pages/Survey/SurveyResult'
import Register from '~/pages/Auth/Register'
import Login from '~/pages/Auth/Login'
import NotFound from '~/pages/NotFound'
import LoginLayout from '~/Layout/LoginLayout'
import DashboardFilter from '~/pages/Dashboard/DashboardFilter'

const publicRoutes = [
  { path: '/register', component: Register, layout: LoginLayout },
  { path: '/login', component: Login, layout: LoginLayout },
  { path: '*', component: NotFound, layout: LoginLayout },
]

const privateRoutes = [
  { path: '/', component: Dashboard },
  { path: '/:surveyStatus', component: DashboardFilter },
  { path: '/create-survey', component: InitSurvey, layout: SurveyInfo },
  { path: '/segment', component: Segment, layout: SurveyInfo },
  { path: '/create-questions', component: QuestionList, layout: SurveyInfo },
  { path: '/final-preview', component: FinalPreview, layout: SurveyInfo },
  { path: '/final-preview/:surveyId', component: FinalPreview, layout: SurveyInfo },
  { path: '/result-survey', component: SurveyResult, layout: SurveyInfo },
  { path: '/result-survey/:surveyId', component: SurveyResult, layout: SurveyInfo },
  { path: '*', component: NotFound, layout: LoginLayout },
]

export { publicRoutes, privateRoutes }

