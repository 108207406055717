/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation, useQuery } from "@tanstack/react-query";
import classNames from "classnames/bind";
import { useState } from "react";
import { useEffect } from "react";
import { memo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import {
  setOnlyView,
  setRegisterPayment,
  setSurveyDataOnlyView,
} from "~/store/actions/SurveyActions";
import ArrowLeft from "~/assets/icon/ArrowLeft";
import FooterNavigationButtons from "~/components/FooterNavigationButtons";
import ModalConfirm from "~/components/ModalConfirm";
import { useSurveyStore } from "~/store/SurveyContext";
import style from "./FinalPreview.module.scss";
import InfoResearch from "./components/InfoResearch/InfoResearch";
import ObjectResearch from "./components/ObjectResearch/ObjectResearch";
import Payment from "./components/Payment";
import PaymentInfo from "./components/PaymentInfo";
import QuestionsResearch from "./components/QuestionsResearch/QuestionsResearch";
import Status from "./components/Status";
import { surveyService } from "~/services/surveyService";

const cx = classNames.bind(style);

function FinalPreview(props) {
  const { surveyId = "" } = useParams();

  const {
    dataSurvey: { id, isRegisterPayment, onlyView },
    dispatch,
    setStepOpen,
  } = useSurveyStore();

  const { data: surveyDataOnlyView } = useQuery({
    queryKey: [`survey-${surveyId}`],
    queryFn: async () => await surveyService.list(`/${surveyId}`),
    enabled: !!surveyId,
  });

  useEffect(() => {
    if (surveyId && surveyDataOnlyView) {
      dispatch(setOnlyView(surveyId));
      dispatch(setSurveyDataOnlyView(surveyDataOnlyView?.data));
    }
  }, [surveyDataOnlyView]);

  useEffect(() => {
    if (onlyView) {
      setStepOpen("all");
    }
  }, [onlyView]);

  const [modalOpen, setModalOpen] = useState(false);
  const methods = useForm({
    defaultValues: {
      paymentMethod: "bank_transfer",
    },
  });

  const { handleSubmit } = methods;

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const {
    mutate,
    data: dataAfterUpdate,
  } = useMutation({
    mutationFn: async ({ id, data }) => await surveyService.update(`${id}/update-payment-method`, data),
    onSuccess() {
      setModalOpen(false);
      dispatch(setRegisterPayment(true));
    }
  });

  const handleConfirmData = (data) => {
    const { paymentMethod } = data;

    const dataUpdate = {
      paymentMethod,
    };

    mutate({ id, data: dataUpdate });
  };

  const { data: dataSurvey } = useQuery({
    queryKey: [`survey-${id || surveyId}-final-review`],
    queryFn: async () => await surveyService.list(`/${id || surveyId}/final-review`),
  });

  return (
    <div className={cx("wrapper", "max-w-[924px] w-full mx-auto mb-[103px]")}>
      <Status dataSurvey={dataSurvey?.data} />
      <div className="flex mt-5">
        <InfoResearch dataSurvey={dataSurvey?.data} className="flex flex-col" />
        <ObjectResearch
          className="flex flex-col"
          dataSegment={dataSurvey?.data?.segment}
        />
        <QuestionsResearch
          dataQuestion={dataSurvey?.data?.question}
          className="flex flex-col"
        />
      </div>
      <FormProvider {...methods}>
        {!isRegisterPayment && !onlyView && !surveyId && (
          <Payment className="mt-5" price={dataSurvey?.data.pricing} />
        )}
      </FormProvider>
      {(isRegisterPayment || onlyView) && (
        <PaymentInfo
          dataSurvey={dataAfterUpdate?.data || dataSurvey?.data}
          pricing={dataSurvey?.data?.pricing}
        />
      )}
      {!isRegisterPayment && !onlyView && (
        <FooterNavigationButtons
          buttonLeft={{
            iconLeft: <ArrowLeft />,
            title: "Quay về",
          }}
          buttonRight={{
            title: "Tạo nghiên cứu",
            onClick() {
              setModalOpen(true);
            },
          }}
        />
      )}
      <ModalConfirm
        className="max-w-[470px]"
        isOpen={modalOpen}
        onRequestClose={handleCloseModal}
        handleCloseModal={handleCloseModal}
        handleConfirm={handleSubmit(handleConfirmData)}
      >
        <div className="modal-content text-center text-[14px] leading-[18px]">
          <h3 className="text-[24px] leading-[31px] font-semibold">
            Xác nhận tạo nghiên cứu
          </h3>
          <p className="mt-[10px]">Bạn có chắc chắn muốn tạo nghiên cứu mới?</p>
          <p>
            Sau khi xác nhận, nghiên cứu sẽ không thể thay đổi được các thông
            tin về đối tượng & bảng câu hỏi.
          </p>
        </div>
      </ModalConfirm>
    </div>
  );
}

export default memo(FinalPreview);
