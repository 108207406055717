import { memo } from "react";

function CheckboxIcon({ className }) {
  return (
    <svg
      className={className}
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="fill-current"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 12.4167C9.99154 12.4167 12.4167 9.99154 12.4167 7C12.4167 4.00846 9.99154 1.58333 7 1.58333C4.00846 1.58333 1.58333 4.00846 1.58333 7C1.58333 9.99154 4.00846 12.4167 7 12.4167ZM7 13.25C10.4518 13.25 13.25 10.4518 13.25 7C13.25 3.54822 10.4518 0.75 7 0.75C3.54822 0.75 0.75 3.54822 0.75 7C0.75 10.4518 3.54822 13.25 7 13.25Z"
        fill="#00D68F"
      />
      <path
        className="fill-current hidden"
        d="M10.3333 7C10.3333 8.84095 8.84095 10.3333 7 10.3333C5.15905 10.3333 3.66667 8.84095 3.66667 7C3.66667 5.15905 5.15905 3.66667 7 3.66667C8.84095 3.66667 10.3333 5.15905 10.3333 7Z"
        fill="#00D68F"
      />
    </svg>
  );
}

export default memo(CheckboxIcon);
