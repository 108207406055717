import { memo } from "react";

function Logo({ className }) {
  return (
    <svg
      className={className}
      width="102"
      height="24"
      viewBox="0 0 102 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M66.5755 0C65.9316 0.000379062 65.4287 0.168557 65.0669 0.508944C64.7047 0.849715 64.5236 1.28342 64.5236 1.81892C64.5236 2.4031 64.7003 2.85451 65.0537 3.17758C65.4071 3.50065 65.9151 3.65997 66.5732 3.65997C67.2314 3.65997 67.6952 3.48737 68.0575 3.14217C68.4197 2.79698 68.6008 2.35442 68.6008 1.81892C68.6008 1.25244 68.4241 0.805459 68.0707 0.48239C67.7177 0.159695 67.2193 0.000368957 66.5755 0Z"
        fill="#00D68F"
      />
      <path
        d="M57.8093 6.04536C58.8739 6.04536 59.7706 6.25779 60.495 6.68265L60.4995 6.68707C61.2239 7.11193 61.7672 7.71381 62.1383 8.49272C62.5049 9.27162 62.6905 10.1921 62.6905 11.2454V18.8044H59.3421V11.719C59.3421 11.1038 59.2317 10.586 59.0108 10.17C58.79 9.75401 58.4719 9.43537 58.0567 9.21409C57.6415 8.99281 57.1556 8.89987 56.6078 8.93085C56.1484 8.93085 55.7288 9.00609 55.3445 9.15656C54.9602 9.3026 54.6245 9.51503 54.3329 9.78057C54.0414 10.0461 53.8205 10.347 53.6615 10.6878C53.5069 11.0286 53.4274 11.3871 53.4274 11.7632V18.8044H50.079V1.32325H53.3788V8.04131C53.4494 7.95722 53.5245 7.87313 53.6041 7.79347C54.112 7.27125 54.7393 6.85082 55.477 6.52775C56.2147 6.20911 56.9921 6.04536 57.8093 6.04536Z"
        fill="#00D68F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.1184 2.89434C12.1785 3.31035 13.0885 3.89453 13.8527 4.64245L13.8571 4.6513C14.6169 5.39923 15.1955 6.27107 15.5887 7.27126C15.9774 8.27144 16.1762 9.36014 16.1762 10.5418C16.1762 11.7234 15.9818 12.8165 15.5887 13.8256C15.1955 14.8302 14.6213 15.7064 13.8659 16.4455C13.1106 17.1846 12.2006 17.7643 11.1316 18.1804C10.0626 18.5964 8.8567 18.8044 7.52267 18.8044H0V2.27033H7.52267C8.8567 2.27033 10.0582 2.47834 11.1184 2.89434ZM11.2332 14.1619C11.6661 13.7149 11.993 13.1839 12.2139 12.5687H12.2183C12.4391 11.9535 12.5496 11.2631 12.5496 10.5373C12.5496 9.81155 12.4391 9.14329 12.2183 8.52813C11.9974 7.91297 11.6705 7.37747 11.2376 6.92164C10.8047 6.4658 10.2658 6.10733 9.62089 5.84622C8.97597 5.58511 8.23828 5.45676 7.4034 5.45676H3.53826V15.6135H7.39899C8.23386 15.6135 8.97155 15.4852 9.61648 15.2241C10.2614 14.9629 10.8003 14.6089 11.2332 14.1619Z"
        fill="#00D68F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.1477 6.04538C26.5612 6.04538 27.8202 6.32862 28.9201 6.88624C30.02 7.44387 30.8902 8.2095 31.5263 9.18756C32.1624 10.1656 32.4804 11.2809 32.4804 12.5422C32.4804 13.8035 32.1624 14.9187 31.5263 15.8968C30.8902 16.8704 30.02 17.6404 28.9201 18.1981C27.8202 18.7601 26.5789 19.0389 25.1477 19.0389C23.7165 19.0389 22.4487 18.7557 21.34 18.1981C20.2357 17.6404 19.361 16.8748 18.7249 15.8968C18.0889 14.9187 17.7708 13.8035 17.7708 12.5422C17.7708 11.2809 18.0889 10.1656 18.7249 9.18756C19.361 8.21392 20.2313 7.44387 21.34 6.88624C22.4487 6.32419 23.7342 6.04538 25.1477 6.04538ZM27.1532 15.6489C27.7495 15.3436 28.2266 14.9187 28.58 14.3744V14.3699C28.9333 13.8256 29.1012 13.2148 29.0879 12.5377C29.1012 11.8429 28.9333 11.2278 28.58 10.6834C28.2266 10.1391 27.7495 9.7142 27.1532 9.40883C26.5524 9.10347 25.8987 8.94857 25.1477 8.94857C24.3968 8.94857 23.7121 9.10789 23.0981 9.42211C22.4841 9.74075 22.0026 10.1612 21.658 10.6878C21.3135 11.2145 21.1456 11.8341 21.1633 12.5422C21.1456 13.2193 21.3135 13.83 21.658 14.3744C22.007 14.9187 22.4841 15.3436 23.0981 15.6489C23.7077 15.9543 24.4056 16.1092 25.1477 16.1092C25.8898 16.1092 26.5568 15.9543 27.1532 15.6489Z"
        fill="#00D68F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.2817 6.90834C44.3453 6.35072 43.2498 6.07191 41.9908 6.07191C41.156 6.07191 40.3697 6.2091 39.632 6.48349C38.8943 6.75787 38.2803 7.1075 37.7944 7.53235C37.6663 7.64742 37.5514 7.76248 37.4454 7.87755L37.3924 6.30204H34.1634V24H37.5338V17.2864C37.5912 17.3439 37.6486 17.4014 37.7105 17.459C38.2052 17.9148 38.8325 18.291 39.5834 18.5831C40.3388 18.8752 41.1383 19.0212 41.9864 19.0212C43.2144 19.0212 44.3011 18.7424 45.2508 18.1848C46.2005 17.6271 46.9426 16.8571 47.4771 15.8835C48.0116 14.9054 48.2767 13.799 48.2767 12.551C48.2767 11.303 48.0072 10.1789 47.4771 9.20965C46.9426 8.24045 46.2094 7.47925 45.2729 6.91719L45.2817 6.90834ZM44.5529 14.4186C44.2304 14.9629 43.7887 15.3834 43.2188 15.6843C42.6534 15.9852 41.9908 16.1313 41.2399 16.1313C40.489 16.1313 39.8219 15.9808 39.2477 15.6843C38.6734 15.3834 38.2273 14.9629 37.9048 14.4186C37.5824 13.8742 37.4233 13.2502 37.4233 12.5421C37.4233 11.834 37.5824 11.2056 37.9048 10.6657C38.2273 10.1213 38.6734 9.70532 39.2477 9.41323C39.8219 9.12114 40.4845 8.9751 41.2399 8.9751C41.9953 8.9751 42.6799 9.12557 43.2454 9.42208C43.8108 9.72302 44.2525 10.139 44.5661 10.6745C44.8798 11.21 45.0388 11.834 45.0388 12.5421C45.0388 13.2502 44.8753 13.8787 44.5573 14.4186H44.5529Z"
        fill="#00D68F"
      />
      <path
        d="M64.9034 18.7999V6.30648H68.2518V18.7999H64.9034Z"
        fill="#00D68F"
      />
      <path
        d="M80.8411 6.68267C80.1123 6.25781 79.2023 6.04538 78.1201 6.04538C77.3205 6.04538 76.5607 6.20913 75.8451 6.52777C75.1295 6.85084 74.5244 7.27127 74.0296 7.79349C73.9236 7.90413 73.8264 8.0192 73.7337 8.13426L73.6895 6.30649H70.4604V18.8H73.8088V11.7146C73.8088 11.3694 73.8883 11.033 74.0429 10.71C74.2019 10.3869 74.4184 10.0904 74.7011 9.82484C74.9838 9.55931 75.3195 9.34245 75.7038 9.17428C76.0881 9.01053 76.5166 8.92645 76.9892 8.92645C77.537 8.92645 78.0229 9.03266 78.4381 9.24509C78.8533 9.45752 79.1714 9.76731 79.3922 10.1789C79.6131 10.5905 79.7235 11.0994 79.7235 11.7146V18.8H83.0719V11.2676C83.0719 10.1789 82.8819 9.25394 82.5064 8.47946C82.131 7.70941 81.5744 7.10752 80.8455 6.68267H80.8411Z"
        fill="#00D68F"
      />
      <path
        d="M95.4491 10.5905L102 18.8044H97.5429L93.0991 12.9847L88.7746 18.8044H84.5295L91.0495 10.3382L84.6488 2.2659H89.0794L93.4216 7.97492L97.4988 2.2659H101.739L95.4491 10.5905Z"
        fill="#00D68F"
      />
    </svg>
  );
}

export default memo(Logo);
