import classNames from "classnames/bind";
import { memo } from "react";
import Button from "~/components/Button";
import CheckboxGroup from "~/components/CheckboxGroup";
import useSlideToggle from "~/hooks/useSlideToggle";

function ToggleCheckboxBlock(props = {}) {
  const { checkboxList, name, title, disabled, open } = props;
  const { contentRef, toggle, isOpen } = useSlideToggle({ open });

  return (
    <div className="first:mt-0 mt-3">
      <div className="flex justify-between items-center" onClick={toggle}>
        <h3 className="text-[14px] leading-[18px] font-semibold">{title}</h3>
        <Button
          className={classNames("max-w-[22px]", isOpen && "rotate-[180deg]")}
        >
          <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="22" height="22" rx="11" fill="#F5F5F5" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.66841 13.2868C6.41619 13.0305 6.41619 12.615 6.66841 12.3587L10.5434 8.42121C10.7956 8.16493 11.2045 8.16493 11.4568 8.42121L15.3318 12.3587C15.584 12.615 15.584 13.0305 15.3318 13.2868C15.0795 13.5431 14.6706 13.5431 14.4184 13.2868L11.0001 9.81333L7.58175 13.2868C7.32954 13.5431 6.92062 13.5431 6.66841 13.2868Z"
              fill="#999999"
            />
          </svg>
        </Button>
      </div>
      <div ref={contentRef} className="overflow-hidden">
        <CheckboxGroup
          columns={2}
          options={checkboxList || []}
          name={name}
          className="gap-y-[19px] gap-x-3 py-2"
          disabled={disabled}
        />
      </div>
    </div>
  );
}

export default memo(ToggleCheckboxBlock);
