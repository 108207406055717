import { memo } from "react";

import GroupAnswer from "~/components/GroupAnswer";

function MultiChoice({ question, ...restProps }) {
  const { questionIndex } = restProps;

  return (
    <GroupAnswer
      question={question}
      questionIndex={questionIndex}
      type="multi_choice"
    />
  );
}

export default memo(MultiChoice);
