import classNames from "classnames/bind";
import { memo } from "react";

import LabelRounded from "~/components/LabelRounded";
import style from "../../Dashboard.module.scss";
import { Link } from "react-router-dom";
import Tooltip from "~/components/Tooltip";

const cx = classNames.bind(style);

const labelStatusClass = {
  happenning: "text-info",
  pending_approve: "text-warning",
  completed: "text-primary",
};

const labelStatusTitle = {
  happenning: "Đang Diễn Ra",
  pending_approve: "Đang chờ duyệt",
  completed: "Hoàn thành",
};

function DashboardBoxItem(props) {
  const {
    image,
    title,
    description,
    questionQuantity,
    createBy,
    status,
    numberStart,
    numberEnd,
    date,
    id,
  } = props;
  const link =
    status === "happenning" || status === "completed"
      ? `/result-survey/${id}`
      : `/final-preview/${id}`;

  return (
    <li className="max-w-[216px] w-full mr-[20px] bg-white rounded-[10px] transition cursor-pointer hover:shadow-[0px_0px_16px_rgba(0,0,0,0.08)]">
      <Link to={link} className="flex flex-col h-full">
        <img
          src={image}
          alt="avatar-survey"
          className={cx("avatar-survey", "rounded-[10px_10px_0_0]")}
        />
        <div
          className={cx("content", "p-[10px_12px_20px] flex flex-col flex-1")}
        >
          <Tooltip text={title}>
            <h3
              className={cx(
                "title",
                "text-[14px] leading-[18px] font-semibold w-fit"
              )}
            >
              {title}
            </h3>
          </Tooltip>
          <p className="text-[10px] leading-[13px] text-[#CCCCCC] mt-[6px]">
            {description}
          </p>
          <div className="flex items-center my-[10px]">
            {status !== "pending_approve" && (
              <>
                <b className="text-[10px] leading-[13px] text-[#999]">
                  {questionQuantity} câu hỏi
                </b>
                <div className="w-1 h-1 rounded-full bg-[#E6E6E6] mx-[6px]"></div>
              </>
            )}
            <p className="text-[10px] leading-[13px] text-[#CCCCCC]">
              Tạo bởi {createBy}
            </p>
          </div>
          {status !== "pending_approve" && (
            <div className="flex items-center justify-between mt-auto">
              <LabelRounded
                title={labelStatusTitle[status]}
                type="outline"
                className={cx(
                  labelStatusClass[status],
                  "text-[10px] leading-[13px] h-[26px] px-3 font-semibold"
                )}
              />
              <div className="text-[10px] leading-[13px] font-semibold text-[#999999]">
                <p className="text-right">
                  <span>{numberStart}</span>/<span>{numberEnd}</span>
                </p>
                {date && (
                  <p className="text-[10px] leading-[13px] mt-[2px] text-[#CCCCCC]">
                    {date}
                  </p>
                )}
              </div>
            </div>
          )}
          {status !== "completed" && status !== "pending_approve" && (
            <div className="bg-[#E6E6E6] rounded-full h-[6px] overflow-hidden mt-[10px]">
              <div
                className="bg-info rounded-full h-full"
                style={{ width: `${(numberStart / numberEnd) * 100}%` }}
              ></div>
            </div>
          )}
        </div>
      </Link>
    </li>
  );
}

export default memo(DashboardBoxItem);
