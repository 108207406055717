import { memo } from "react";
import { useParams } from "react-router-dom";

import BoxShadow from "~/components/BoxShadow";
import ExcelIcon from "~/assets/image/excel.png";
import { useSurveyStore } from "~/store/SurveyContext";

function ExportFileTab(props) {
  const { surveyId } = useParams();
  const {
    dataSurvey: { id = surveyId, name },
  } = useSurveyStore();

  return (
    <BoxShadow className="p-[30px] flex justify-between items-center">
      <div className="left">
        <p className="text-[16px] leading-[20px] font-semibold">
          Kết quả {name}
        </p>
        <div className="title-14 !font-normal mt-2">16:48 - 21/02/2023</div>
      </div>

      <a
        className="flex items-center py-3 px-6 text-info border border-current rounded-[6px]"
        href={`https://dophinx-api.proteanstudios.com/api/v1/surveys/${id}/download-excel`}
        target="_blank"
        rel="noreferrer"
      >
        <span className="block title-14 !font-normal">Xuất file Excel</span>
        <img src={ExcelIcon} alt="excel-icon" className="w-[22px] ml-[10px]" />
      </a>
    </BoxShadow>
  );
}

export default memo(ExportFileTab);
