import classNames from "classnames/bind";
import { useFormContext, useWatch } from "react-hook-form";

import Button from "../Button";
import Input from "../Input";
import style from "./GroupAnswer.module.scss";
import { useSurveyStore } from "~/store/SurveyContext";

const cx = classNames.bind(style);

function GroupAnswer(props) {
  const {
    dataSurvey: { onlyView },
  } = useSurveyStore();
  const { className, questionIndex, question } = props;
  const { options = [], type } = question;

  const { control, update } = useFormContext();
  const questions = useWatch({
    control,
    name: "questions",
  });
  
  
  const handleDeleteOption = (optionIndex) => {
    options.splice(optionIndex, 1);

    update(questionIndex, {
      ...questions[questionIndex],
      options: options,
    });
  };

  const handleAddOption = () => {
    update(questionIndex, {
      ...questions[questionIndex],
      options: [...options, "New option"],
    });
  };

  return (
    <ul className={cx(className, "mt-3")}>
      {options.map((option, i) => (
        <li key={i} className="flex items-stretch mt-3 first:mt-0 min-h-[32px]">
          {(type === "multi_choice" || type === "single_choice") && (
            <div
              className={cx(
                "w-[12.5px] h-[12.5px] mr-2 border border-current self-center",
                type === "multi_choice" ? "rounded-[4px]" : "rounded-full"
              )}
            ></div>
          )}
          <div className="flex-1 relative mr-3">
            <Input
              control={control}
              name={`questions.${questionIndex}.options.${i}`}
              value={option}
              className={cx("option", "block h-full w-full outline-none")}
              inputProps={{
                disabled: onlyView,
              }}
            />
            <div className={cx("line")}></div>
          </div>
          {options.length !== 1 && !onlyView && (
            <Button onClick={() => handleDeleteOption(i)}>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.9597 2.62639C12.3502 2.23586 12.9834 2.23586 13.3739 2.62639C13.7645 3.01691 13.7645 3.65008 13.3739 4.0406L9.41438 8.00016L13.3739 11.9597C13.7645 12.3502 13.7645 12.9834 13.3739 13.3739C12.9834 13.7645 12.3502 13.7645 11.9597 13.3739L8.00016 9.41438L4.0406 13.3739C3.65008 13.7645 3.01691 13.7645 2.62639 13.3739C2.23587 12.9834 2.23586 12.3502 2.62639 11.9597L6.58595 8.00016L2.62639 4.0406C2.23587 3.65008 2.23587 3.01691 2.62639 2.62639C3.01691 2.23587 3.65008 2.23587 4.0406 2.62639L8.00016 6.58595L11.9597 2.62639Z"
                  fill="#B3B3B3"
                />
              </svg>
            </Button>
          )}
        </li>
      ))}
      {!onlyView && (
        <li
          className="flex items-center mt-3 first:mt-0 min-h-[32px] cursor-pointer"
          onClick={() => handleAddOption()}
        >
          <div
            className={cx(
              "w-[12.5px] h-[12.5px] mr-2 border border-current",
              type === "multi_choice" ? "rounded-[4px]" : "rounded-full"
            )}
          ></div>
          <p className="text-[#B3B3B3]">Thêm đáp án</p>
        </li>
      )}
    </ul>
  );
}

export default GroupAnswer;
