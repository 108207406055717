import { axiosClient } from "./axiosClient"

export const getData = async (url, options = {}) => {
  return await axiosClient.get(url, {
    ...options
  })
}

export const convertNewSegment = async (oldSegment) => {
  let newSegment = {};

  if (Array.isArray(oldSegment)) {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < oldSegment.length; i++) {
      if (oldSegment[i].name === 'place') {
        const itemClone = { ...oldSegment[i] };
        delete itemClone.name;
        newSegment.province = itemClone;
      }

      if (oldSegment[i].name === 'age') {
        const itemClone = { ...oldSegment[i] };
        delete itemClone.name;
        newSegment.age = itemClone;
      }

      if (oldSegment[i].name === 'gender') {
        const itemClone = { ...oldSegment[i] };
        delete itemClone.name;
        newSegment.gender = itemClone.value;
      }

      if (oldSegment[i].name === 'job') {
        const itemClone = { ...oldSegment[i] };
        delete itemClone.name;
        newSegment.job = itemClone.value;
      }

      if (oldSegment[i].name === 'income') {
        const itemClone = { ...oldSegment[i] };
        delete itemClone.name;
        newSegment.income = itemClone.value;
      }

      if (oldSegment[i].name === 'marital') {
        const itemClone = { ...oldSegment[i] };
        delete itemClone.name;
        newSegment.marital = itemClone.value;
      }
    }
  } else {
    newSegment = oldSegment;
  }

  return newSegment;
};