const optionsQuantity = [
  {
    value: "100",
    label: "100",
  },
  {
    value: "200",
    label: "200",
  },
  {
    value: "300",
    label: "300",
  },
  {
    value: "500",
    label: "500",
  },
  {
    value: "1.000",
    label: "1.000",
  },
  {
    value: "2.000",
    label: "2.000",
  },
  {
    value: "3.000",
    label: "3.000",
  },
  {
    value: "4.000",
    label: "4.000",
  },
  {
    value: "5.000",
    label: "5.000",
  },
];

export { optionsQuantity };

export const dataQuestionType = [
  {
    value: "free_text",
    title: "Free Text",
    icon_url:
      "https://dophinx.s3.ap-southeast-1.amazonaws.com/question_types/free_text.svg",
  },
  {
    value: "yes_no",
    title: "Yes/No",
    icon_url:
      "https://dophinx.s3.ap-southeast-1.amazonaws.com/question_types/yes_no.svg",
  },
  {
    value: "multi_choice",
    title: "Multi Choice",
    icon_url:
      "https://dophinx.s3.ap-southeast-1.amazonaws.com/question_types/multi_choice.svg",
  },
  {
    value: "single_choice",
    title: "Single Choice",
    icon_url:
      "https://dophinx.s3.ap-southeast-1.amazonaws.com/question_types/single_choice.svg",
  },
  {
    value: "level_choice",
    title: "Level Choice",
    icon_url:
      "https://dophinx.s3.ap-southeast-1.amazonaws.com/question_types/level_choice.svg",
  },
  {
    value: "reorder_choice",
    title: "Reorder Choice",
    icon_url:
      "https://dophinx.s3.ap-southeast-1.amazonaws.com/question_types/reorder_choice.svg",
  },
  {
    value: "images_choice",
    title: "Images Choice",
    icon_url:
      "https://dophinx.s3.ap-southeast-1.amazonaws.com/question_types/images_choice.svg",
  },
];
