import classNames from "classnames/bind";
import { memo } from "react";
import { Link, useParams } from "react-router-dom";

import style from "./SectionItem.module.scss";
import { useGlobalState } from "~/store/GlobalContext";

const cx = classNames.bind(style);

function SectionItem(props) {
  const { surveyStatus } = useParams();
  const { title, surveyNumber, className, type, ...restProps } = props;
  const {
    globalState: { menuCollapse },
  } = useGlobalState();

  return (
    <Link
      className={cx(
        "item-sidebar",
        className,
        menuCollapse && "justify-center",
        surveyStatus === type && "active"
      )}
      to={`/${type}`}
    >
      <div className="relative z-10 flex items-center justify-center py-3">
        <div className="w-4 h-4 flex items-center">{restProps.icon}</div>
        <div
          className={cx(
            "flex items-center flex-1 ml-[6px] transition-all",
            menuCollapse && "max-w-0 overflow-hidden h-0"
          )}
        >
          <div className={cx("title", "flex-1")}>{title}</div>
          <div className={cx("number", "text-right")}>{surveyNumber}</div>
        </div>
      </div>
    </Link>
  );
}

export default memo(SectionItem);
