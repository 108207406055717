import reducerMiddleware from "~/middleware/reducerMiddleware"

const addDataSurvey = (state, value) => {
  return {
    ...state,
    ...value
  }
}

const formatDataCheckboxes = (state, value) => {
  const { segment } = state
  const newSegment = segment?.map(item => {
    const dataNeedFormat = value?.includes(item.name)

    if (dataNeedFormat) {
      const { value: valueArr } = item
      const newValue = valueArr?.map(val => val.key)

      return {
        ...item,
        value: newValue
      }
    }

    return item
  })


  return {
    ...state,
    segment: newSegment
  }
}

const setRegisterPayment = (state) => {
  return {
    ...state,
    isRegisterPayment: true,
    onlyView: true
  }
}

const setOnlyView = (state, payload) => {
  return {
    ...state,
    id: payload,
    onlyView: true
  }
}

const setSurveyDataOnlyView = (state, dataSurvey) => {
  let { questions, name, segment, targetNumber, description, type, ...rest } = dataSurvey


  questions = questions.map(question => {
    let { choices } = question

    choices = choices?.map(choice => {
      const title = choice.title || choice.level_title

      if (title) {
        return {
          title,
          value: choice.value
        }
      }

      return choice.value
    })

    return {
      ...question, options: choices
    }
  })

  const surveyDataOnlyView = {
    name, segment, targetNumber, description, newQuestions: questions, type, ...rest
  }

  return {
    ...state,
    ...surveyDataOnlyView
  }
}

const actionsObj = {
  addDataSurvey, formatDataCheckboxes, setRegisterPayment, setOnlyView, setSurveyDataOnlyView
}

const surveyReducer = reducerMiddleware(actionsObj)

export default surveyReducer 