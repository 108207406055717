import classNames from "classnames";
import { memo } from "react";
import Select from "react-dropdown-select";
import { Controller } from "react-hook-form";

import { $ } from "~/App";

function SelectDropdown(props) {
  const { control, name, options, className, ...restProps } = props;

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        const handleChange = (e) => {
          onChange(e[0].value);
        };

        return (
          <>
            <Select
              className={classNames("select-general", className)}
              color="#00D68F"
              onChange={handleChange}
              options={options}
              values={value ? [value] : []}
              onDropdownCloseRequest={({ close, state }) => {
                const dropdownEl = $(".select-general .react-dropdown-select-dropdown");
                dropdownEl.style.animation = "hide 350ms ease-in-out forwards";
        
                setTimeout(() => {
                  close();
                }, 500);
              }}
              onDropdownOpen={() => {
                const dropdownEl = $(".select-general .react-dropdown-select-dropdown");
                dropdownEl &&
                  (dropdownEl.style.animation = "show 350ms ease-in-out forwards");
              }}
              {...restProps}
            />
            {error && <p className="mt-3 text-[red] italic">{error.message}</p>}
          </>
        );
      }}
    />
  );
}

export default memo(SelectDropdown);
