/* eslint-disable no-useless-constructor */
import baseService from "~/api/BaseService";

class SurveyService extends baseService {
  BASE_ENDPOINT = '/surveys'

  constructor(props) {
    super(props)
  }
}

export const surveyService = new SurveyService()