import classNames from "classnames/bind";
import { memo } from "react";

function YesNo() {
  return (
    <>
      <ul>
        <li className="flex items-center min-h-[30px]">
          <div
            className={classNames(
              "w-[12.5px] h-[12.5px] mr-2 border border-current self-center mb-[2px]",
              "rounded-full"
            )}
          ></div>
          Có
        </li>
        <li className="flex items-center min-h-[30px] mt-[6px]">
          <div
            className={classNames(
              "w-[12.5px] h-[12.5px] mr-2 border border-current self-center mb-[2px]",
              "rounded-full"
            )}
          ></div>
          Không
        </li>
      </ul>
    </>
  );
}

export default memo(YesNo);
