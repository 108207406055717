import { memo } from "react";

function Play({ className }) {
  return (
    <svg
      className={className}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 1.5C13.1337 1.5 16.5 4.86469 16.5 9.00434C16.5 13.1353 13.1337 16.5 9 16.5C4.86631 16.5 1.5 13.1353 1.5 9.00434C1.5 4.86469 4.86631 1.5 9 1.5ZM8.14575 6.02243C7.98649 6.02243 7.83446 6.05861 7.68967 6.13097C7.50869 6.23227 7.3639 6.39146 7.28427 6.57959C7.23359 6.70984 7.15396 7.10058 7.15396 7.10781C7.07432 7.53473 7.03089 8.22938 7.03089 8.99638C7.03089 9.72793 7.07432 10.3929 7.13948 10.8271C7.14672 10.8343 7.22635 11.3191 7.31322 11.4855C7.47249 11.7894 7.78378 11.9776 8.1168 11.9776H8.14575C8.36293 11.9703 8.81902 11.7822 8.81902 11.775C9.58639 11.4566 11.0994 10.4653 11.7075 9.8068L11.751 9.76339C11.8306 9.68379 11.9319 9.56078 11.9537 9.53184C12.0695 9.37988 12.1274 9.19175 12.1274 9.00434C12.1274 8.79378 12.0623 8.59841 11.9392 8.43922C11.9102 8.41028 11.8016 8.28726 11.7003 8.18596C11.1067 7.5492 9.55743 6.50724 8.74662 6.18886C8.62355 6.13893 8.31226 6.02967 8.14575 6.02243Z"
        fill="#999999"
      />
    </svg>
  );
}

export default memo(Play);

