function ArrowBot({ className }) {
  return (
    <svg
      className={className}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5693 6.05963C14.8936 6.38913 14.8936 6.92337 14.5693 7.25287L9.58715 12.3154C9.26288 12.6449 8.73712 12.6449 8.41285 12.3154L3.43071 7.25287C3.10643 6.92337 3.10643 6.38913 3.43071 6.05963C3.75498 5.73012 4.28073 5.73012 4.60501 6.05963L9 10.5255L13.395 6.05963C13.7193 5.73012 14.245 5.73012 14.5693 6.05963Z"
        fill="#999999"
      />
    </svg>
  );
}

export default ArrowBot;
