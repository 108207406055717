import classNames from "classnames/bind";
import { memo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import Input from "~/components/Input";
import HeadingBig from "~/components/HeadingBig";

import style from "./Login.module.scss";
import Button from "~/components/Button";
import { axiosClient } from "~/api/axiosClient";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { authMemberService } from "~/services/authMemberService";

const cx = classNames.bind(style);

function Login(props) {
  const [passwordShown, setPasswordShown] = useState(false);
  const [error, setError] = useState(false);
  const navigation = useNavigate();
  const methods = useForm({
    mode: "onSubmit",
    defaultValues: {
      email: "",
      password: "",
    },
    resolver: yupResolver(
      yup.object({
        email: yup
          .string()
          .email("Email chưa nhập đúng định dạng.")
          .required("Email không được để trống!"),
        password: yup
          .string()
          .required("Mật khẩu không được để trống!")
          .min(8, "Mật khẩu phải có ít nhất 8 ký tự."),
      })
    ),
  });
  const { handleSubmit } = methods;

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const submit = async (data) => {
    const dataSubmit = {
      email: data.email,
      password: data.password,
    };

    return await authMemberService.create("/login", dataSubmit)
      .then((res) => {
        const {
          data: {
            member: { isEmailVerified, name },
          },
        } = res;

        if (!isEmailVerified) {
          toast.error("Vui lòng xác nhận địa chỉ email đăng ký!");
          return;
        }

        const token = res.data.tokens.access.token;
        const userId = res.data.member.id;
        localStorage.setItem("token", token);
        localStorage.setItem("userId", userId);
        localStorage.setItem("userName", name);
        navigation("/");
      })
      .catch((err) => {
        const statusCode = err.response.data.code;

        if (statusCode === 500) {
          setError("Lỗi máy chủ, vui lòng thử lại sau.");
          return;
        }

        setError(err.response.data.message);
      });
  };

  return (
    <div className={cx("content-form w-[332px] form")}>
      <FormProvider {...methods}>
        <HeadingBig className={cx("heading field")} title="Đăng nhập" />
        <div className="relative">
          <img
            src={require("~/assets/image/message.png")}
            alt="icon"
            className="absolute top-[14px] left-[15px]"
          />
          <Input
            placeholder="Nhập email"
            name="email"
            inputProps={{
              className: cx(
                "input-field",
                "!mt-[30px]",
                error === "Tài khoản không tồn tại" && "!border-[#FF4D4D]"
              ),
            }}
          />
        </div>

        <div className="relative">
          <img
            src={require("~/assets/image/lock.png")}
            alt="icon"
            className="absolute top-[14px] left-[15px]"
          />
          <Input
            placeholder="Nhập mật khẩu"
            type={passwordShown ? "" : "password"}
            name="password"
            inputProps={{
              className: cx(
                "input-field",
                "!mt-[20px]",
                error === "Mật khẩu không đúng" && "!border-[#FF4D4D]"
              ),
            }}
          />
          <div
            className={cx(
              "icon-eye-wrapper",
              "absolute top-[14px] right-[15px] cursor-pointer",
              passwordShown && "show"
            )}
          >
            <img
              src={require("~/assets/image/show.png")}
              alt="icon"
              onClick={togglePassword}
              className=""
            />
          </div>
        </div>

        {error ? (
          <div className="box-error flex justify-center items-center mt-[20px] text-[#FF4D4D]">
            <img
              src={require("~/assets/image/information.png")}
              alt=""
              className="mr-1"
            />
            <p className="pt-1">{error}</p>
          </div>
        ) : (
          ""
        )}

        <div className="flex justify-center mt-[30px]">
          <Button
            className="bg-[#00D68F] px-[42px] py-[12px] rounded-[66px] 
                    text-[12px] text-[#FFFFFF] font-semibold"
            onClick={handleSubmit(submit)}
          >
            Đăng nhập
          </Button>
        </div>
      </FormProvider>
      <div className="mt-[30px] text-[12px] justify-between flex">
        <Link className="text-[#1A8CFF] text-[12px]" to="#">
          Bạn quên mật khẩu?
        </Link>
        <Link className="text-[#00D68F] text-[12px]" to="/register">
          Tạo tài khoản mới
        </Link>
      </div>
    </div>
  );
}

export default memo(Login);
