/* eslint-disable no-useless-constructor */
import baseService from "~/api/BaseService";

class StaticDataService extends baseService {
  BASE_ENDPOINT = '/static_data'

  constructor(props) {
    super(props)
  }
}

export const staticDataService = new StaticDataService()