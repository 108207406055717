import classNames from "classnames/bind";
import Slider from "rc-slider";
import { memo } from "react";

import style from "../../Segment.module.scss";

const cx = classNames.bind(style);

function AgeRange(props) {
  const { handleDataRange, ageRange } = props;

  return (
    <div className={cx("field", "max-w-[320px] w-full ml-auto")}>
      <div className={cx("title-14", "max-w-[132px] w-full")}>Độ tuổi</div>
      <div className="mt-[74px] flex items-center title-14 text-[#B3B3B3] min-h-[18px]">
        <div>10</div>
        <Slider
          className={cx("age-range", "mx-4")}
          handleRender={(renderProps) => {
            const { props } = renderProps;
            return (
              <div {...props}>
                <div
                  className={cx(
                    "age-number",
                    "flex justify-center items-center font-semibold w-11 h-11 border border-[#E6E6E6] rounded-full absolute bottom-full left-1/2 -translate-x-1/2 -translate-y-5 text-[#333]"
                  )}
                >
                  {props["aria-valuenow"]}
                </div>
              </div>
            );
          }}
          range
          min={10}
          max={90}
          allowCross={false}
          defaultValue={[ageRange.min || 20, ageRange.max || 40]}
          onChange={(value) => handleDataRange(value)}
        />
        <div>90</div>
      </div>
    </div>
  );
}

export default memo(AgeRange);
