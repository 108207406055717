import classNames from "classnames/bind";
import { memo } from "react";
import Modal from "react-modal";
import BoxShadow from "../BoxShadow";
import Button from "../Button";

Modal.setAppElement("#root");

function ModalConfirm(props) {
  const { children, className, handleCloseModal, handleConfirm, ...restProps } =
    props;

  return (
    <Modal
      className={classNames(
        "outline-none absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2",
        className
      )}
      overlayClassName="bg-[rgba(51,51,51,0.2)] inset-0 fixed"
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
      {...restProps}
    >
      <BoxShadow className="px-[60px] py-[30px]">
        {children}
        <div className="flex justify-center items-center mt-[30px] text-[14px] leading-[18px]">
          <Button
              className="min-w-[156px] h-[46px] border-current text-[#B3B3B3] hover:brightness-90"
            variant="outline"
            onClick={() => handleCloseModal()}
          >
            Quay lại
          </Button>
          <Button
            className="min-w-[156px] h-[46px] ml-5 hover:brightness-90"
            variant="fill"
            onClick={() => handleConfirm()}
          >
            Xác nhận
          </Button>
        </div>
      </BoxShadow>
    </Modal>
  );
}

export default memo(ModalConfirm);
