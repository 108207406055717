import { useQuery } from "@tanstack/react-query";
import classNames from "classnames";
import { useMemo } from "react";
import { memo } from "react";
import { useParams } from "react-router-dom";

import Users from "~/assets/icon/Users";
import BoxShadow from "~/components/BoxShadow";
import { colorSurveyStatus } from "~/helper";
import { surveyService } from "~/services/surveyService";
import { useSurveyStore } from "~/store/SurveyContext";

function BoxUser(props) {
  const { surveyId } = useParams();
  const {
    dataSurvey: { id = surveyId },
  } = useSurveyStore();

  const { data } = useQuery({
    queryKey: ["survey-progress"],
    queryFn: async () => await surveyService.list(`/${id}/survey-progress`),
  });

  const {
    status = "pending_approve",
    totalSuitableUsers = {},
    totalCompletedUsers = 0,
    targetNumber = 0,
    progress = 0,
  } = useMemo(() => {
    return data?.data || {};
  }, [data]);

  return (
    <BoxShadow className="mt-5 flex min-h-[160px]">
      <div
        className={classNames(
          "flex items-center justify-center max-w-[162px] w-full bg-current rounded-[12px]",
          colorSurveyStatus[status]
        )}
      >
        <Users className="max-w-[84px]" />
      </div>
      <div className="flex items-center py-9 text-[14px] leading-[18px] text-[#B3B3B3] flex-1">
        <div className="mx-8 max-w-[160px] w-full">
          <p className="text-[28px] leading-[36px] text-[#333333] font-semibold">
            {totalSuitableUsers?.min} - {totalSuitableUsers?.max}
          </p>
          <p className="mt-[10px]">Số lượng đáp viên phù hợp nghiên cứu</p>
        </div>
        <div className="line h-full border-l border-[#E6E6E6]"></div>
        <div className="mx-8 max-w-[218px] w-full">
          <p className="text-[28px] leading-[36px] text-[#333333] font-semibold">
            <span className={classNames(colorSurveyStatus[status])}>{totalCompletedUsers}</span>/
            {targetNumber}
          </p>
          <p className="mt-[10px]">Số lượng đáp viên đạt yêu cầu</p>
        </div>
        <div className="line h-full border-l border-[#E6E6E6]"></div>
        <div className="mx-8 max-w-[170px]">
          <p className={classNames("text-[28px] leading-[36px] font-semibold", colorSurveyStatus[status])}>
            {+progress}%
          </p>
          <p className="my-[10px]">Tiến độ nghiên cứu</p>
          <div className="min-h-[6px] rounded-full bg-[#CCCCCC] overflow-hidden">
            <div
              className="bg-info h-[6px]"
              style={{ width: `${+progress}%` }}
            ></div>
          </div>
        </div>
      </div>
    </BoxShadow>
  );
}

export default memo(BoxUser);
