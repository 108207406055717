import { useReducer } from "react";
import GlobalContext from "~/store/GlobalContext";
import globalStateReducer from "../reducer/globleStateReducer";

const init = {
  menuCollapse: false,
  userId: localStorage.getItem('userId')
}

function GlobalState({ children }) {
  const [globalState, dispatch] = useReducer(globalStateReducer, init)

  return (
    <GlobalContext.Provider value={{ globalState, dispatch }}>
      {children}
    </GlobalContext.Provider>
  );
}

export default GlobalState;