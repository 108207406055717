/* eslint-disable react-hooks/exhaustive-deps */
import classNames from "classnames/bind";
import { memo, useMemo } from "react";

import BoxShadow from "~/components/BoxShadow";
import Button from "~/components/Button";
import { getPlace } from "~/helper/getInfoSurvey";
import style from "../../FinalPreview.module.scss";

const cx = classNames.bind(style);

function ObjectResearch(props) {
  const { className, dataSegment } = props;

  const place = useMemo(() => getPlace(dataSegment), [dataSegment]);

  const fields = useMemo(
    () => [
      { label: "Địa điểm", value: place },
      {
        label: "Độ tuổi",
        value: `${dataSegment?.age?.min} - ${dataSegment?.age?.max}`,
      },
      {
        label: "Giới tính",
        value: dataSegment?.gender && dataSegment.gender.join(", "),
      },
      {
        label: "Công việc",
        value: dataSegment?.job && dataSegment.job.join(", "),
      },
      {
        label: "Mức lương",
        value: dataSegment?.income && dataSegment.income.join(", "),
      },
      {
        label: "[Loại trừ]",
        value: dataSegment?.marital && dataSegment.marital.join(", "),
      },
    ],
    [dataSegment]
  );

  return (
    <BoxShadow className={cx("flex-1 px-[30px] py-5 mx-5", className)}>
      <h3 className="text-[16px] leading-[21px] font-semibold">
        Đối tượng nghiên cứu
      </h3>
      <div className="mt-5 mb-3">
        {fields.map(({ label, value }) => (
          <div key={label} className={cx("field", "flex")}>
            <p className="color-desc flex-1 mr-[10px]">{label}</p>
            <p className="font-semibold max-w-[140px] w-full">{value}</p>
          </div>
        ))}
      </div>
      <Button type='link' to='/segment' className="text-info mx-auto mt-auto">Xem toàn bộ</Button>
    </BoxShadow>
  );
}

export default memo(ObjectResearch);