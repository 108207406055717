import classNames from "classnames/bind";
import { memo } from "react";
import RadioInput from "../RadioInput";

function RadioGroup(props) {
  const {
    options,
    name,
    className,
    columns = 1,
    control,
    classNameInput,
    disabled,
  } = props;

  return (
    <div
      className={classNames("grid", className)}
      style={{
        gridTemplateColumns: `repeat(${columns}, 1fr)`,
      }}
    >
      {
        options?.map((option, i) => (
          <RadioInput
            key={i}
            control={control}
            name={name}
            value={option.value}
            label={option.label}
            className={classNameInput}
            inputProps={{ disabled }}
          />
        ))}
    </div>
  );
}

export default memo(RadioGroup);
