import { mapNewData } from "~/helper";

function addOnlyCitySelected(state, payload) {
  return mapNewData(state, 'place', {
    isAll: false,
    only: payload,
    ignore: []
  })
}

function addExceptCitySelected(state, payload) {
  return mapNewData(state, 'place', {
    isAll: false,
    only: [],
    ignore: payload
  })
}

function chooseAllCities(state) {
  return mapNewData(state, 'place', {
    only: [],
    ignore: [],
    isAll: true
  })
}

const addGenderSelected = (state, payload) => {
  const newFormatPayload = payload.map(item => item?.key)

  return mapNewData(state, 'gender', {
    value: newFormatPayload
  })
}

const addAgeRange = (state, payload) => {
  return mapNewData(state, 'age', {
    min: payload[0],
    max: payload[1]
  })
}

const addInfoAdvanced = (state, payload) => {
  const { data, type } = payload

  const newFormatPayload = data.map(item => item.key)

  return mapNewData(state, type, {
    value: newFormatPayload
  })
}

const actionsObj = {
  addOnlyCitySelected,
  addExceptCitySelected,
  chooseAllCities, addGenderSelected, addAgeRange, addInfoAdvanced
}

const objectInfoReducer = (state, { action, payload }) => {
  const newState = [...state]

  return actionsObj[action](newState, payload) || state
}

export default objectInfoReducer


