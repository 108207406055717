import classNames from "classnames";

function Copy({ className }) {
  return (
    <svg
      className={className}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.2998 2.25C5.92701 2.25 5.6248 2.55221 5.6248 2.925V12.375C5.6248 12.7478 5.92701 13.05 6.29981 13.05H14.3998C14.7726 13.05 15.0748 12.7478 15.0748 12.375V2.925C15.0748 2.55221 14.7726 2.25 14.3998 2.25H6.2998Z"
        fill="#999999"
      />
      <path
        d="M4.2748 5.625C4.2748 5.25221 3.9726 4.95 3.5998 4.95C3.22701 4.95 2.9248 5.25221 2.9248 5.625V15.075C2.9248 15.4478 3.22701 15.75 3.5998 15.75H11.6998C12.0726 15.75 12.3748 15.4478 12.3748 15.075C12.3748 14.7022 12.0726 14.4 11.6998 14.4H4.2748V5.625Z"
        fill="#999999"
      />
    </svg>
  );
}

export default Copy;
