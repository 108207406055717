/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { memo } from "react";
import classNames from "classnames";
import { useRef } from "react";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";

import QuestionDetailWrapper from "../QuestionDetailWrapper";
import SingleChoice from "../SingleChoice";
import MultiChoice from "../MultiChoice";
import FreeText from "../FreeText";
import YesNo from "~/components/YesNo";
import LevelChoice from "../LevelChoice";
import ReorderChoice from "../ReorderChoice";
import ImagesChoice from "../ImagesChoice";

const typeQuestionComponent = {
  single_choice: SingleChoice,
  multi_choice: MultiChoice,
  free_text: FreeText,
  yes_no: YesNo,
  level_choice: LevelChoice,
  reorder_choice: ReorderChoice,
  images_choice: ImagesChoice
};

function QuestionListDetailContainer(props) {
  const elPicking = useRef();
  const { fields } = useFormContext();
  const { questionPicking, setQuestionPicking, setQuestionPickingPosition } =
    props;

  useEffect(() => {
  
    setQuestionPickingPosition(elPicking.current.offsetTop);
  }, [questionPicking]);

  return (
    <div className="">
      <ul>
        {fields.map((questionItem, i) => {
          const { type } = questionItem;
          const Comp = typeQuestionComponent[type];

          if (!Comp) return;

          return (
            <li
              className="mt-5 first:mt-0"
              key={questionItem.id}
              onClick={(e) => {
                setQuestionPicking(i);
              }}
              id={`question-${i + 1}`}
              ref={(i === questionPicking && elPicking) || undefined}
            >
              <QuestionDetailWrapper
                questionIndex={i}
                question={questionItem}
                className={classNames(
                  "border transition",
                  i === questionPicking ? "border-primary" : "border-white"
                )}
              >
                <Comp question={questionItem} questionIndex={i} />
              </QuestionDetailWrapper>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default memo(QuestionListDetailContainer);
