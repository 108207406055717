
const ADD_DATA_SURVEY = 'addDataSurvey'
const FORMAT_DATA_CHECKBOXES = 'formatDataCheckboxes'
const SET_REGISTER_PAYMENT = 'setRegisterPayment'
const SET_ONLY_VIEW = 'setOnlyView'
export {
  FORMAT_DATA_CHECKBOXES,
  ADD_DATA_SURVEY,
  SET_REGISTER_PAYMENT,
  SET_ONLY_VIEW
}

const ADD_ONLY_CITY_SELECTED = 'addOnlyCitySelected'
const ADD_EXCEPT_CITY_SELECTED = 'addExceptCitySelected'
const CHOOSE_ALL_CITIES = 'chooseAllCities'
const ADD_GENDER_SELECTED = 'addGenderSelected'
const ADD_AGE_RANGE = 'addAgeRange'
const ADD_INFO_ADVANCED = 'addInfoAdvanced'

export {
  ADD_ONLY_CITY_SELECTED,
  ADD_EXCEPT_CITY_SELECTED,
  CHOOSE_ALL_CITIES,
  ADD_GENDER_SELECTED,
  ADD_AGE_RANGE,
  ADD_INFO_ADVANCED
}