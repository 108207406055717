/* eslint-disable react-hooks/exhaustive-deps */
import { Route, Routes, useHref, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";

import "./assets/scss/GlobalStyle.scss";
import "~/assets/scss/slider-range-style.scss";
import { showLayout } from "./helper";
import { privateRoutes, publicRoutes } from "./routes";
import GlobalState from "./store/Provider/GlobalState";

export const $ = document.querySelector.bind(document)

function App() {
  const navigation = useNavigate();
  const token = localStorage.getItem('token')
  const pathname = useHref();

  /*
    Route authen:
    - Nếu ko có token?
      + Nếu trang nằm trong public route thì ko bị đẩy về Login
      + Ko thể vào được các trang private route
      + Bị đẩy ra trang login

    - Nếu có token?
      + Nếu trang nằm trong private route thì ko bị đẩy về Dashboard
      + Ko thể vào được các trang public route
      + Bị đẩy ra trang Dashboard
  */

  useEffect(() => {
    const isInPublicRoute = publicRoutes.some((route) =>
      route.path.includes(pathname)
    );
    const isInPrivateRoute = privateRoutes.some((route) =>
      route.path.includes(pathname)
    );

    if (!token && isInPrivateRoute) {
      navigation("/login");
    }

    if (token && isInPublicRoute) {
      navigation("/");
    }
  }, [pathname]);

  return (
    <GlobalState>
      <div className="App">
        <Routes>
          {/* {token && */}
          {
            privateRoutes.map((route, i) => {
              const Layout = showLayout(route.layout);
              const Page = route.component;

              return (
                <Route
                  key={i}
                  path={route.path}
                  element={
                    <Layout>
                      <Page />
                    </Layout>
                  }
                />
              );
            })}
          {!token &&
            publicRoutes.map((route, i) => {
              const Layout = showLayout(route.layout);
              const Page = route.component;

              return (
                <Route
                  key={i}
                  path={route.path}
                  element={
                    <Layout>
                      <Page />
                    </Layout>
                  }
                />
              );
            })}
        </Routes>
        <ToastContainer />
      </div>
    </GlobalState>
  );
}

export default App;
