const createAction = actionName => (payload => ({
  action: actionName,
  payload
}))

const TOGGLE_MENU = 'toggleMenu'
const ADD_USER_ID = 'addUserId'

export const toggleMenu = createAction(TOGGLE_MENU)
export const addUserId = createAction(ADD_USER_ID)

