import classNames from "classnames/bind";
import { memo } from "react";
import { Link, useNavigate } from "react-router-dom";

import Button from "~/components/Button";
import Logo from "~/assets/icon/Logo";
import style from "./Header.module.scss";
import { useGlobalState } from "~/store/GlobalContext";
import { toggleMenu } from "~/store/actions/GlobalStateActions";
import { useState } from "react";
import BoxShadow from "~/components/BoxShadow";

const cx = classNames.bind(style);

function Header(props) {
  const [showModal, setShowModal] = useState(false);
  const {
    dispatch,
    globalState: { menuCollapse },
  } = useGlobalState();
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    navigate("/login");
  };

  return (
    <div className="wrapper px-[23px] min-h-[60px] flex justify-between items-center border-b border-[#E6E6E6]">
      <div className="left flex items-center">
        <Button
          onClick={() => {
            dispatch(toggleMenu());
          }}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M3 6C3 5.44772 3.40294 5 3.9 5H20.1C20.5971 5 21 5.44772 21 6C21 6.55228 20.5971 7 20.1 7H3.9C3.40294 7 3 6.55228 3 6Z"
              fill="#999999"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M3 12C3 11.4477 3.40294 11 3.9 11H20.1C20.5971 11 21 11.4477 21 12C21 12.5523 20.5971 13 20.1 13H3.9C3.40294 13 3 12.5523 3 12Z"
              fill="#999999"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M3 18C3 17.4477 3.40294 17 3.9 17H20.1C20.5971 17 21 17.4477 21 18C21 18.5523 20.5971 19 20.1 19H3.9C3.40294 19 3 18.5523 3 18Z"
              fill="#999999"
            />
          </svg>
        </Button>
        <Link
          to="/"
          className={cx(
            "logo block max-w-[102px] ml-5 transition",
            menuCollapse && "max-w-0"
          )}
        >
          <Logo />
        </Link>
      </div>
      <div className="account flex items-center">
        <Button
          variant="outline"
          color="info"
          className="font-semibold text-[12px] leading-[16px] h-[32px] px-5 hover:text-white hover:bg-info transition"
        >
          Hỗ trợ
        </Button>
        <div className="ml-5 relative">
          <Button onClick={() => setShowModal(!showModal)}>
            <svg
              width="36"
              height="36"
              viewBox="0 0 36 36"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M33 18C33 26.2843 26.2843 33 18 33C9.71573 33 3 26.2843 3 18C3 9.71573 9.71573 3 18 3C26.2843 3 33 9.71573 33 18ZM18 20.8125C14.0294 20.8125 10.553 22.8662 8.625 25.9406C11.9605 29.5987 15.1119 30.1875 18 30.1875C20.8881 30.1875 24.0396 29.1238 27.375 25.9406C25.447 22.8662 21.9706 20.8125 18 20.8125ZM18 18.9375C20.5888 18.9375 22.6875 16.8388 22.6875 14.25C22.6875 11.6612 20.5888 9.5625 18 9.5625C15.4112 9.5625 13.3125 11.6612 13.3125 14.25C13.3125 16.8388 15.4112 18.9375 18 18.9375Z"
                fill="#999999"
              />
            </svg>
          </Button>
          <BoxShadow
            className={cx(
              "absolute right-0 top-[110%] bg-white rounded-[6px] p-3 w-max z-20 opacity-0 pointer-events-none transition cursor-pointer",
              showModal && "pointer-events-auto opacity-100"
            )}
            onClick={handleLogout}
          >
            Log out
          </BoxShadow>
        </div>
      </div>
    </div>
  );
}

export default memo(Header);
