import classNames from "classnames/bind";

import style from "./SidebarSection.module.scss";
import { useGlobalState } from "~/store/GlobalContext";

const cx = classNames.bind(style);

function SidebarSection({ className, title, children }) {
  const {
    globalState: { menuCollapse },
  } = useGlobalState();

  return (
    <div className={cx("wrapper", className)}>
      <h3
        className={cx("heading", "min-h-[42px] py-3", menuCollapse && "hidden")}
      >
        {title}
      </h3>
      {children}
    </div>
  );
}

export default SidebarSection;
