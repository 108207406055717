import classNames from "classnames";
import { memo } from "react";

function BackToTop({ className }) {
  return (
    <div
      className={classNames(
        "flex justify-center items-center w-9 h-9 rounded-full bg-white shadow-[0px_0px_16px_rgba(0,0,0,0.08)]",
        className
      )}
    >
      <svg
        className="w-4 h-4"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className="transition fill-current"
          d="M3.09216 5.97627C2.74695 6.31637 2.74695 6.86882 3.09216 7.20891C3.43599 7.54765 3.99242 7.54765 4.33625 7.20891L7.11897 4.46742L7.11897 13.6296C7.11897 14.1127 7.51574 14.5 7.99992 14.5C8.4841 14.5 8.88087 14.1127 8.88087 13.6296L8.88087 4.46742L11.6636 7.20891C12.0074 7.54765 12.5639 7.54765 12.9077 7.20891C13.2529 6.86882 13.2529 6.31637 12.9077 5.97627L8.62196 1.75405C8.45657 1.5911 8.23276 1.5 7.99992 1.5C7.76707 1.5 7.54327 1.5911 7.37787 1.75405L3.09216 5.97627Z"
        />
      </svg>
    </div>
  );
}

export default memo(BackToTop);
