import { useContext } from "react"
import { createContext } from "react"

const GlobalContext = createContext()

const useGlobalState = () => {
  return useContext(GlobalContext)
}

export default GlobalContext
export { useGlobalState }