/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useRef, useEffect } from 'react';

function useSlideToggle(props = {}) {
  const { open } = props
  const [isOpen, setIsOpen] = useState(open);
  const contentRef = useRef(null);

  useEffect(() => {
    const el = contentRef.current

    if (el) {
      const contentHeight = el.scrollHeight;
      el.style.transition = props?.duration ? `${props?.duration}s` : '0.5s'

      if (isOpen) {
        el.style.height = contentHeight + 'px';
      } else {
        el.style.height = '0';
      }
    }
  }, [isOpen]);

  function toggle() {
    setIsOpen(!isOpen);
  }

  const slideClose = () => {
    setIsOpen(false)
  }

  const slideOpen = () => {
    setIsOpen(true)
  }

  return {
    toggle,
    contentRef,
    isOpen,
    slideOpen,
    slideClose
  };
}

export default useSlideToggle;
