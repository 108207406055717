const getInfoSegment = (segment, name) => {
  return segment.filter((item) => item.name === name);
}

const getPlace = (dataSegment) => {
  const only = dataSegment?.province?.only;
  const ignore = dataSegment?.province?.ignore;

  if (ignore?.length > 0) {
    const citiesIgnore = ignore.join(", ");

    return `Toàn bộ các tỉnh thành, trừ ${citiesIgnore}`;
  }

  if (only?.length > 0) {
    const onlyCities = only.join(", ");

    return `Chỉ các tỉnh: ${onlyCities}`;
  }

  return "Tất cả các tỉnh";
}

const getGender = (segment) => {
  const genderInfo = getInfoSegment(segment, 'gender');

  return genderInfo[0].value[0] === "male"
    ? "Nam"
    : genderInfo[0].value[0] === "female"
      ? "Nữ"
      : "Cả 2 giới";
}

const getInfoCheckboxes = (segment, name) => {
  let jobsInfo = segment.filter((item) => item.name === name)[0].value;

  return jobsInfo.map((job) => job.label).join(", ");
}

export {
  getPlace, getGender, getInfoCheckboxes
}