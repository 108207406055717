/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { useQueries } from "@tanstack/react-query";
import classNames from "classnames/bind";
import { useCallback, useEffect, useMemo, useState } from "react";
import { memo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { addInfoAdvanced } from "~/store/actions/ObjectInfoActions";

import { getData } from "~/api/fetchApi";
import HeadingBig from "~/components/HeadingBig";
import { advancedInfoKeyName, staticDataCheckboxCategories } from "~/helper";
import { useSurveyStore } from "~/store/SurveyContext";
import style from "../../Segment.module.scss";
import SelectedBar from "../SelectedBar";
import ToggleCheckboxBlock from "../ToggleCheckboxBlock";
import { staticDataService } from "~/services/staticDataService";

const cx = classNames.bind(style);

function AdvancedInfo(props) {
  const {
    dataSurvey: { segment, onlyView },
  } = useSurveyStore();
  const { dispatch } = props;

  const [dataJobs, dataIncomes, dataMaritalStates] = useQueries({
    queries: staticDataCheckboxCategories.map((category) => ({
      queryKey: [category.key],
      queryFn: async () => await staticDataService.list(category.url),
    })),
  });

  const jobDefaultValue = useMemo(() => {
    const dataJobsAPI = dataJobs?.data?.data;
    const job = segment.find((item) => item.name === "job");

    return dataJobsAPI
      ?.filter((jobItem) => job.value.includes(jobItem.value))
      .map((jobItem) => ({
        ...jobItem,
        label: jobItem.title,
        key: jobItem.value,
      }));
  }, [dataJobs]);

  const incomeDefaultValue = useMemo(() => {
    const dataIncomesAPI = dataIncomes?.data?.data;
    const income = segment.find((item) => item.name === "income");

    return dataIncomesAPI
      ?.filter((jobItem) => income.value.includes(jobItem.value))
      .map((jobItem) => ({
        ...jobItem,
        label: jobItem.title,
        key: jobItem.value,
      }));
  }, [dataIncomes]);

  const maritalDefaultValue = useMemo(() => {
    const dataMaritalAPI = dataMaritalStates?.data?.data;
    const marital = segment.find((item) => item.name === "marital");

    return dataMaritalAPI
      ?.filter((jobItem) => marital.value.includes(jobItem.value))
      .map((jobItem) => ({
        ...jobItem,
        label: jobItem.title,
        key: jobItem.value,
      }));
  }, [dataMaritalStates]);

  const methods = useForm({
    defaultValues: {
      job: jobDefaultValue || [],
      income: incomeDefaultValue || [],
      marital: maritalDefaultValue || [],
    },
  });

  // take all data from useForm
  const { watch, setValue } = methods;
  const keysCategory = Object.keys(watch());
  const { job, income, marital } = watch();

  useEffect(() => {
    dispatch(addInfoAdvanced({ data: job, type: "job" }));
  }, [job]);

  useEffect(() => {
    dispatch(addInfoAdvanced({ data: income, type: "income" }));
  }, [income]);

  useEffect(() => {
    dispatch(addInfoAdvanced({ data: marital, type: "marital" }));
  }, [marital]);

  const handleRemoveAllItem = useCallback((keyCat) => {
    setValue(keyCat, []);
  }, []);

  return (
    <FormProvider {...methods}>
      <div className={cx("section")}>
        <HeadingBig className={cx("heading")} title="Nhân khẩu học nâng cao" />
        <div className="flex">
          <div className="flex-1 max-w-[402px] mr-[60px]">
            {dataJobs?.data?.data && <ToggleCheckboxBlock
              checkboxList={dataJobs?.data?.data}
              name="job"
              title="Nghề nghiệp"
              disabled={onlyView}
              open={true}
            />}
            <ToggleCheckboxBlock
              checkboxList={dataIncomes?.data?.data}
              name="income"
              title="Thu nhập"
              disabled={onlyView}
            />
            <ToggleCheckboxBlock
              checkboxList={dataMaritalStates?.data?.data}
              name="marital"
              title="Tình trạng hôn nhân"
              disabled={onlyView}
            />
          </div>
          <div className="flex-1 max-w-[402px]">
            <h3 className="text-[16px] leading-[20px] font-semibold">
              Các yếu tố được chọn
            </h3>
            {keysCategory.map((keyCat, i) => {
              const haveCheckboxSelection = watch(keyCat).length > 0;
              if (!haveCheckboxSelection) return;

              return (
                <SelectedBar
                  key={i}
                  title={advancedInfoKeyName[keyCat]}
                  selection={watch(keyCat)
                    .map((selected) => selected.label)
                    .join(", ")}
                  handleRemoveAllItem={() => handleRemoveAllItem(keyCat)}
                />
              );
            })}
          </div>
        </div>
      </div>
    </FormProvider>
  );
}

export default memo(AdvancedInfo);
