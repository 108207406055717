import classNames from "classnames/bind";
import { memo } from "react";
import { Controller } from "react-hook-form";

import style from "./RadioInput.module.scss";
import CheckboxIcon from "~/assets/icon/CheckboxIcon";

const cx = classNames.bind(style);

function RadioInput(props) {
  const {
    control,
    label,
    name,
    value: radioValue,
    className,
    inputProps,
  } = props;

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, ref, value } }) => (
        <label
          htmlFor={`${name}-${label}`}
          className={cx(
            "wrapper",
            "flex items-center cursor-pointer w-fit",
            className
          )}
        >
          <input
            type="radio"
            id={`${name}-${label}`}
            className="hidden"
            value={radioValue}
            checked={value === radioValue}
            onChange={onChange}
            ref={ref}
            {...inputProps}
          />
          <CheckboxIcon className={cx("checkmark", "mr-2")} />
          <div className={cx("label", "w-max pt-[1px] transition-[0.2s]")}>{label}</div>
        </label>
      )}
    />
  );
}

export default memo(RadioInput);
