import classNames from "classnames";
import { memo } from "react";
import BoxShadow from "~/components/BoxShadow";
import { convertSecondToDay, convertToVndFormat } from "~/helper";

function Status(props) {
  const { className, dataSurvey = {} } = props;
  const { status = 'pending_approve', statusText, estimatedTime } = dataSurvey;

  return (
    <BoxShadow className={classNames("p-[30px]", className)}>
      <h3 className="text-[18px] leading-[23px] text-center">
        <span className="font-semibold block">{dataSurvey?.name}</span>
        <span>({dataSurvey?.description})</span>
      </h3>
      <div className="flex mt-[30px] text-[14px] leading-[18.2px]">
        <div className="status max-w-[387px] w-full">
          <p className="color-desc">Trạng thái</p>
          <p
            className={classNames(
              "font-semibold mt-[6px]",
              status !== 'pending_approve' ? "text-info" : "text-warning"
            )}
          >
            {statusText}
          </p>
        </div>
        <div className="fee mx-[90px]">
          <p className="color-desc">Tổng chi phí</p>
          <p className="font-semibold mt-[6px]">
            {convertToVndFormat(dataSurvey?.pricing)} VND
          </p>
        </div>
        <div className="timebox">
          <p className="color-desc">Thời lượng hoàn thành</p>
          <p className="font-semibold mt-[6px]">{estimatedTime}</p>
        </div>
      </div>
    </BoxShadow>
  );
}

export default memo(Status);
