import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { memo } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { toast } from "react-toastify";

import DeleteImage from "~/assets/icon/DeleteImage";
import Photo from "~/assets/icon/Photo";
import Button from "~/components/Button";
import { uploadFileService } from "~/services/uploadFileService";
import { useSurveyStore } from "~/store/SurveyContext";

function ImagesChoice(props) {
  const {
    dataSurvey: { onlyView },
  } = useSurveyStore();
  const { questionIndex } = props;
  const { update, control } = useFormContext();
  const question = useWatch({
    control,
    name: `questions.${questionIndex}`,
  });
  const { options = [] } = question;

  const { mutateAsync } = useMutation({
    mutationFn: async (file) =>
      await uploadFileService.create(
        { file },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      ),
  });

  const handleAddMultipleImages = async (fileList) => {
    // Remove file is not image
    fileList = [...fileList].map(
      (file) => file.type.includes("image/") && file
    ).filter(Boolean)

    // Show error message when all file is not images
    if (fileList.length === 0) {
      toast.error('Những file được tải lên không phải là ảnh! Vui lòng chọn lại!')
      return
    }

    const imageLinksList = await Promise.all(
      fileList.map((file) => mutateAsync(file))
    ).then((res = []) => res.map((resItem) => resItem.data.Location));

    update(questionIndex, {
      ...question,
      options: [...options, ...imageLinksList],
    });
  };

  const handleDeleteImage = (index) => {
    options.splice(index, 1);

    update(questionIndex, {
      ...question,
      options,
    });
  };

  return (
    <div className="wrapper">
      {options.length > 0 && (
        <div className="grid grid-cols-3 gap-3">
          {options.map((image, i) => (
            <div className="relative" key={i}>
              <img
                src={image}
                className="block w-full overflow-hidden bg-[#ddd] aspect-square rounded-[12px] object-cover"
                alt=""
              />
              <Button
                className="absolute top-[10px] right-[10px]"
                onClick={() => handleDeleteImage(i)}
              >
                <DeleteImage />
              </Button>
            </div>
          ))}
        </div>
      )}
      <Button className="flex items-center gap-1 py-[6px] mt-3 relative">
        <Photo className="w-5" />
        <p>Tải hình ảnh</p>
        <input
          type="file"
          accept="image/*"
          multiple
          onChange={(e) => handleAddMultipleImages(e.target.files)}
          className="opacity-0 absolute inset-0 cursor-pointer z-10"
          disabled={onlyView}
        />
      </Button>
    </div>
  );
}

export default memo(ImagesChoice);
