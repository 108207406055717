const authenticateMiddleware = (config = {}) => {
  const token = localStorage.getItem('token')

  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }

  return config
}

export default authenticateMiddleware