import axios from "axios"

export const baseUrl = process.env.REACT_APP_API
export const mediaDomain = process.env.REACT_APP_API_MEDIA

export const axiosClient = (baseURL = '', middlewareRequest = config => config) => {
  const instance = axios.create({
    baseURL,
    headers: {
      'Content-Type': 'application/json'
    }
  })

  // Request interceptor
  instance.interceptors.request.use((config) => {
    return middlewareRequest(config)
  }, function (error) {
    // Do something with request error
    return Promise.reject(error);
  })

  return instance
}