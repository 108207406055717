import classNames from "classnames/bind";
import { memo } from "react";
import Setting from "~/assets/icon/Setting";
import UserCircle from "~/assets/icon/UserCircle";
import OptionItem from "./OptionItem";

import style from "./OptionsFooter.module.scss";

const cx = classNames.bind(style);

function OptionsFooter({ className }) {
  return (
    <div className={cx(className)}>
      <OptionItem icon={<UserCircle />} title="Mời thành viên" />
      <OptionItem icon={<Setting />} title="Quản lý workspace" />
    </div>
  );
}

export default memo(OptionsFooter);
