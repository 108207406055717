import { memo } from "react";

function Dragable(props) {
  const { className } = props;

  return (
    <svg
      className={className}
      width="10"
      height="14"
      viewBox="0 0 10 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="fill-current transition-all"
        d="M4.16683 12.0002C4.16683 12.9168 3.41683 13.6668 2.50016 13.6668C1.5835 13.6668 0.833496 12.9168 0.833496 12.0002C0.833496 11.0835 1.5835 10.3335 2.50016 10.3335C3.41683 10.3335 4.16683 11.0835 4.16683 12.0002ZM2.50016 5.3335C1.5835 5.3335 0.833496 6.0835 0.833496 7.00016C0.833496 7.91683 1.5835 8.66683 2.50016 8.66683C3.41683 8.66683 4.16683 7.91683 4.16683 7.00016C4.16683 6.0835 3.41683 5.3335 2.50016 5.3335ZM2.50016 0.333496C1.5835 0.333496 0.833496 1.0835 0.833496 2.00016C0.833496 2.91683 1.5835 3.66683 2.50016 3.66683C3.41683 3.66683 4.16683 2.91683 4.16683 2.00016C4.16683 1.0835 3.41683 0.333496 2.50016 0.333496ZM7.50016 3.66683C8.41683 3.66683 9.16683 2.91683 9.16683 2.00016C9.16683 1.0835 8.41683 0.333496 7.50016 0.333496C6.5835 0.333496 5.8335 1.0835 5.8335 2.00016C5.8335 2.91683 6.5835 3.66683 7.50016 3.66683ZM7.50016 5.3335C6.5835 5.3335 5.8335 6.0835 5.8335 7.00016C5.8335 7.91683 6.5835 8.66683 7.50016 8.66683C8.41683 8.66683 9.16683 7.91683 9.16683 7.00016C9.16683 6.0835 8.41683 5.3335 7.50016 5.3335ZM7.50016 10.3335C6.5835 10.3335 5.8335 11.0835 5.8335 12.0002C5.8335 12.9168 6.5835 13.6668 7.50016 13.6668C8.41683 13.6668 9.16683 12.9168 9.16683 12.0002C9.16683 11.0835 8.41683 10.3335 7.50016 10.3335Z"
        fill="#CCCCCC"
      />
    </svg>
  );
}

export default memo(Dragable);
