import { useQueries } from "@tanstack/react-query";
import classNames from "classnames/bind";
import { memo, useMemo } from "react";

import DashboardCategory from "./components/DashboardCategory";
import style from "./Dashboard.module.scss";
import { surveyService } from "~/services/surveyService";

const cx = classNames.bind(style);

const surveyCategories = [
  {
    name: "Đang diễn ra",
    type: "happenning",
  },
  {
    name: "Đã hoàn thành",
    type: "completed",
  },
  {
    name: "Đang chờ duyệt",
    type: "pending_approve",
  },
];

function Dashboard(props) {
  const userId = localStorage.getItem("userId");
  const userName = localStorage.getItem("userName");

  const dataArr = useQueries({
    queries: surveyCategories.map((category) => ({
      queryKey: [`${category.type}-survey-list`],
      queryFn: async () =>
        await surveyService.list("", {
          status: category.type,
          sortBy: "createdAt:desc",
          limit: 10,
          page: 1,
          onlySuitable: 0,
          memberId: userId,
        }),
    })),
  });

  const surveyCategoriesUpdate = useMemo(() => {
    const dataArrNew = dataArr?.map((data, i) => ({
      ...surveyCategories[i],
      surveyListData: data?.data?.data?.results,
    }));

    return dataArrNew;
  }, [dataArr]);

  return (
    <div
      className={cx("wrapper", "px-5 py-[30px] max-w-[1200px] w-full mx-auto")}
    >
      <div
        className={cx("welcome", "text-[20px] leading-[26px] font-semibold")}
      >
        Xin chào, {userName}!
      </div>
      {surveyCategoriesUpdate.map((category, i) => (
        <DashboardCategory
          key={i}
          categoryName={category.name}
          surveyList={category.surveyListData || []}
          type={category.type}
        />
      ))}
    </div>
  );
}

export default memo(Dashboard);
