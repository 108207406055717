import classNames from "classnames";
import { memo } from "react";
import { Controller } from "react-hook-form";

function TextArea({ control, name, ...restProps }) {
  const { inputProps, placeholder, className, value: valueProp } = restProps;

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <>
            <textarea
              className={classNames("block w-full outline-none", className)}
              placeholder={placeholder}
              onChange={onChange}
              defaultValue={valueProp ? valueProp : undefined}
              value={!valueProp ? value : undefined}
              {...inputProps}
              style={{
                resize: "none",
              }}
            ></textarea>
            {error && <p className="mt-2 text-[red] italic">{error.message}</p>}
          </>
        );
      }}
    />
  );
}

export default memo(TextArea);
