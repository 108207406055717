import reducerMiddleware from "~/middleware/reducerMiddleware"

const toggleMenu = (state) => {
  const { menuCollapse } = state

  return {
    ...state,
    menuCollapse: !menuCollapse
  }
}

const addUserId = (state, id) => {
  return {
    ...state,
    userId: id
  }
}

const actionsObj = {
  toggleMenu, addUserId
}

const globalStateReducer = reducerMiddleware(actionsObj)

export default globalStateReducer 