import { memo } from "react";

function Clock({ className }) {
  return (
    <svg
      className={className}
      width="84"
      height="84"
      viewBox="0 0 84 84"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M42 7C61.355 7 77 22.68 77 42C77 61.355 61.355 77 42 77C22.68 77 7 61.355 7 42C7 22.68 22.68 7 42 7ZM40.775 24.255C39.34 24.255 38.15 25.41 38.15 26.88V44.555C38.15 45.465 38.64 46.305 39.445 46.795L53.165 54.985C53.585 55.23 54.04 55.37 54.53 55.37C55.405 55.37 56.28 54.915 56.77 54.075C57.505 52.85 57.12 51.24 55.86 50.47L43.4 43.05V26.88C43.4 25.41 42.21 24.255 40.775 24.255Z"
        fill="white"
      />
    </svg>
  );
}

export default memo(Clock);
