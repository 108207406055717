/* eslint-disable no-useless-constructor */
import baseService from "~/api/BaseService";

class uploadFile extends baseService {
  BASE_ENDPOINT = '/aws/upload'

  constructor(props) {
    super(props)
  }
}

export const uploadFileService = new uploadFile()