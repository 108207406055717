import classNames from "classnames/bind";
import { memo } from "react";

import BoxShadow from "~/components/BoxShadow";
import Button from "~/components/Button";
import { convertSecondToMinute } from "~/helper";
import style from "../../FinalPreview.module.scss";

const cx = classNames.bind(style);

function QuestionsResearch(props) {
  const { className, dataQuestion } = props;

  return (
    <BoxShadow className={cx("flex-1 px-[30px] py-5", className)}>
      <h3 className="text-[16px] leading-[21px] font-semibold">Bảng câu hỏi</h3>
      <div className="mt-5">
        <div className={cx("field")}>
          <p className="color-desc">Số lượng câu hỏi</p>
          <p className="font-semibold mt-[6px]">
            {dataQuestion?.count} câu hỏi
          </p>
        </div>
        <div className={cx("field")}>
          <p className="color-desc">Thời lượng dự kiến</p>
          <p className="font-semibold mt-[6px]">{convertSecondToMinute(dataQuestion?.estimatedTime)} phút</p>
        </div>
      </div>
      <Button type='link' to='/create-questions' className="text-info mx-auto mt-6">Xem toàn bộ</Button>
    </BoxShadow>
  );
}

export default memo(QuestionsResearch);
