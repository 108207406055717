import classNames from "classnames/bind";
import { memo } from "react";

import BoxShadow from "~/components/BoxShadow";
import Button from "~/components/Button";
import { convertToVndFormat } from "~/helper";
import PaymentMethods from "../PaymentMethods";

function Payment(props) {
  const { className, price } = props;

  return (
    <BoxShadow className={classNames("wrapper p-[30px] flex", className)}>
      <div className="left max-w-[304px] w-full">
        <h3 className="text-[16px] leading-[21px] font-semibold">
          Thanh toán ngay
        </h3>
        <div className="mt-5 bg-info min-h-[70px] rounded-[6px] flex items-center px-5 text-[24px] leading-[31px] font-semibold text-white">
          {convertToVndFormat(price)} vnđ
        </div>
        <div className="flex mt-5">
          <div className="support color-desc">
            <p>Cần hỗ trợ thanh toán?</p>
            <p>Liên hệ với chúng tôi ngay!</p>
          </div>
          <Button
            className="ml-auto rounded-full"
            color="info"
            variant="outline"
          >
            Liên hệ
          </Button>
        </div>
      </div>
      <div className="right flex-1 ml-[60px]">
        <h3 className="text-[16px] leading-[21px] font-semibold">
          Phương thức thanh toán
        </h3>
        <PaymentMethods />
      </div>
    </BoxShadow>
  );
}

export default memo(Payment);
