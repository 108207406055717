import { useQuery } from "@tanstack/react-query";
import classNames from "classnames/bind";
import { useMemo } from "react";
import { memo } from "react";
import { useFormContext } from "react-hook-form";

import { getData } from "~/api/fetchApi";
import BoxShadow from "~/components/BoxShadow";
import { dataQuestionType } from "~/helper/FakeData";
import questionTypeDataTemplate, {
  typeQuestionsNewData,
} from "~/helper/QuestionTypeData";
import { useSurveyStore } from "~/store/SurveyContext";

function BoxSelectTypeQuestion(props) {
  const {
    dataSurvey: { onlyView },
  } = useSurveyStore();
  const { className, questionIndex, question } = props;
  const { type: questionType } = question;
  const { update } = useFormContext();

  const { data } = useQuery({
    queryKey: ["question_types"],
    queryFn: async () => getData("/static_data/question_types"),
  });

  const dataConvert = useMemo(() => {
    const arrQuestionType = data?.data;

    return arrQuestionType || dataQuestionType
  }, [data]);

  const handleChangeQuestionType = (type) => {
    const nameFunctionNewData = typeQuestionsNewData[type]
    if (!nameFunctionNewData) return

    const dataReplace = questionTypeDataTemplate[nameFunctionNewData]()

    update(questionIndex, dataReplace);
  };

  return (!onlyView &&
    <BoxShadow className={className}>
      <ul>
        {dataConvert?.map((type) => (
          <li
            key={type.value}
            className={classNames(
              "py-2 cursor-pointer flex items-center",
              questionType === type.value && "text-primary"
            )}
            onClick={() => handleChangeQuestionType(type.value)}
          >
            <img src={type.icon_url} alt="icon-question-type" className="w-5 h-5 mr-[6px] p-[3.75px]" />
            <p>{type.title}</p>
          </li>
        ))}
      </ul>
    </BoxShadow>
  );
}

export default memo(BoxSelectTypeQuestion);
