import { memo } from "react";

function Photo(props) {
  const { className } = props;

  return (
    <svg
      className={className}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9255 2.25C14.2133 2.25 15.7502 3.85525 15.7502 6.24384V11.7563C15.7502 14.1449 14.2133 15.7502 11.9248 15.7502H6.07472C3.78693 15.7502 2.25 14.1449 2.25 11.7563V6.24384C2.25 3.85525 3.78693 2.25 6.07472 2.25H11.9255ZM12.6698 9.37138C11.9463 8.91998 11.3876 9.55389 11.2369 9.7566C11.0917 9.95235 10.9668 10.1683 10.8351 10.3842C10.5134 10.9171 10.1448 11.5315 9.50676 11.8889C8.57955 12.4023 7.87566 11.9293 7.3693 11.5852C7.17924 11.457 6.99462 11.3358 6.81067 11.255C6.35725 11.0593 5.9493 11.2822 5.34384 12.0512C5.02617 12.4532 4.71122 12.8516 4.3922 13.2487C4.20146 13.4862 4.24694 13.8526 4.5042 14.0115C4.91485 14.2643 5.41579 14.4002 5.98189 14.4002H11.6707C11.9917 14.4002 12.3135 14.3563 12.6203 14.256C13.3113 14.0303 13.8597 13.5134 14.1462 12.8307C14.3878 12.2567 14.5052 11.5601 14.2792 10.9805C14.2039 10.7883 14.0912 10.6092 13.933 10.4518C13.5183 10.0401 13.1307 9.6556 12.6698 9.37138ZM6.63679 4.95004C5.70619 4.95004 4.95004 5.70721 4.95004 6.63756C4.95004 7.5679 5.70619 8.32508 6.63679 8.32508C7.56671 8.32508 8.32354 7.5679 8.32354 6.63756C8.32354 5.70721 7.56671 4.95004 6.63679 4.95004Z"
        fill="#999999"
      />
    </svg>
  );
}

export default memo(Photo);
