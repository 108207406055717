function ArrowLeft({ className }) {
  return (
    <svg
      className={className}
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9754 16.3968C13.6093 16.7571 13.0157 16.7571 12.6496 16.3968L7.02459 10.861C6.65847 10.5007 6.65847 9.91657 7.02459 9.55627L12.6496 4.02055C13.0157 3.66025 13.6093 3.66025 13.9754 4.02055C14.3415 4.38086 14.3415 4.96503 13.9754 5.32534L9.01332 10.2087L13.9754 15.092C14.3415 15.4523 14.3415 16.0365 13.9754 16.3968Z"
        fill="#00D68F"
      />
    </svg>
  );
}

export default ArrowLeft;
