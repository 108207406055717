import classNames from "classnames/bind";
import { memo } from "react";

import style from "../scss/IconStyle.module.scss";

const cx = classNames.bind(style);

function Label({ color, className, fillColorClassName }) {
  return (
    <svg
      className={className}
      width="14"
      height="10"
      viewBox="0 0 14 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={cx("fill-current", fillColorClassName)}
        d="M0.333334 1.66666C0.333334 1.11437 0.781049 0.666656 1.33333 0.666656H8.46812C8.71516 0.666656 8.95346 0.7581 9.13708 0.923362L12.8408 4.25669C13.2822 4.65394 13.2822 5.34604 12.8408 5.74328L9.13708 9.07662C8.95346 9.24188 8.71516 9.33332 8.46812 9.33332H1.33333C0.78105 9.33332 0.333334 8.88561 0.333334 8.33332V1.66666Z"
        fill={color || "#00D68F"}
      />
    </svg>
  );
}

export default memo(Label);
