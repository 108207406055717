import authenticateMiddleware from "~/middleware/authenticateMiddleware"
import { axiosClient } from "./axiosClient"

export const domain = process.env.REACT_APP_API

class baseService {
  BASE_URL = `${domain}/api/v1`
  BASE_ENDPOINT = '/surveys'
  MIDDLEWARE = authenticateMiddleware
  pimaryKey = 'id'

  constructor(props) {
    this.setRequest()
  }

  setRequest() {
    this.request = axiosClient(this.BASE_URL, this.MIDDLEWARE)
  }

  find(id = '', config = {}) {
    const api = `${this.BASE_ENDPOINT}/${id}`
    return this.request.get(api, config)
  }

  list(path = '', query = {}, config = {}) {
    const { BASE_ENDPOINT, request } = this
    const api = `${BASE_ENDPOINT}${path}`
    return request.get(api, { params: query, ...config })
  }

  create = (path = '', data = {}, config = {}) => {
    const { BASE_ENDPOINT, request } = this
    const api = `${BASE_ENDPOINT}${path}`
    return request.post(api, data, config)
  }

  updateAll = (data = {}, config = {}) => {
    const { pimaryKey, BASE_ENDPOINT, request } = this
    return request.put(`${BASE_ENDPOINT}/${data[pimaryKey]}`, data, config)
  }

  update = (id = '', data, config = {}) => {
    const { BASE_ENDPOINT, request } = this
    return request.patch(`${BASE_ENDPOINT}/${id}`, data, config)
  }

  delete = (id, config = {}) => {
    const { BASE_ENDPOINT, request } = this
    const api = `${BASE_ENDPOINT}/${id}`
    return request.delete(api, config)
  }
}

export default baseService