/* eslint-disable react-hooks/exhaustive-deps */
import classNames from "classnames/bind";
import { memo, useMemo } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import FieldForm from "./FieldForm";
import style from "./InitSurvey.module.scss";
import HeadingBig from "~/components/HeadingBig";
import Input from "~/components/Input";
import TextArea from "~/components/TextArea";
import { useSurveyStore } from "~/store/SurveyContext";
import { addDataSurvey } from "~/store/actions/SurveyActions";
import SelectDropdown from "~/components/SelectDropdown";
import FooterNavigationButtons from "~/components/FooterNavigationButtons";
import Library from "~/assets/icon/Library";
import { staticDataService } from "~/services/staticDataService";

const cx = classNames.bind(style);

function InitSurvey(props) {
  const { dispatch, dataSurvey, setStepOpen } = useSurveyStore();
  const { name, description, type, onlyView } = dataSurvey;
  const navigation = useNavigate();

  const { data: dataTargetList } = useQuery({
    queryKey: ["survey-target"],
    queryFn: async () => await staticDataService.list("/surveys"),
  });

  const targetList = useMemo(() => {
    const targetArr = dataTargetList?.data?.types;

    return (
      targetArr?.map((target) => ({
        value: target.value,
        label: target.title,
      })) || []
    );
  }, [dataTargetList]);

  const targetOnlyView = useMemo(() => {
    return targetList?.find(targetItem => {
      return targetItem.value === type
    })
  }, [dataTargetList])

  const { handleSubmit, control } = useForm({
    mode: "onSubmit",
    defaultValues: {
      name: name || "",
      description: description ?? "Không có mô tả",
      type: targetOnlyView || "",
    },
    resolver: yupResolver(
      yup.object({
        name: yup.string().required("Tên nghiên cứu không được để trống!"),
        type: yup.string().required('Cần lựa chọn Mục tiêu nghiên cứu')
      })
    ),
  });

  const handleSubmitForm = (data) => {
    dispatch(addDataSurvey(data));
    navigation("/segment");
    setStepOpen(prev => prev + 1)
  };

  return (
    <form className={cx("wrapper")} onSubmit={handleSubmit(handleSubmitForm)}>
      <div className="max-w-[924px] mx-auto p-[30px] rounded-[12px] bg-white border border-[#DCDCDC] shadow-[0px_0px_8px_rgba(0,0,0,0.08)]">
        <div className="max-w-[688px] mx-auto">
          <HeadingBig title="Tạo nghiên cứu mới" />
          <div className={cx("form")}>
            <FieldForm label="Tên nghiên cứu*">
              <Input
                control={control}
                placeholder="Điền câu trả lời"
                name="name"
                inputProps={{
                  className: cx("input-field"),
                  disabled: onlyView
                }}
              />
            </FieldForm>
            <FieldForm label="Mục tiêu nghiên cứu*" className="has-select">
              <SelectDropdown
                control={control}
                name="type"
                options={targetList}
                disabled={onlyView}
                className={cx("input-field")}
              />
            </FieldForm>
            <FieldForm label="Thông tin chi tiết">
              <TextArea
                control={control}
                placeholder={description ?? "Điền câu trả lời"}
                name="description"
                inputProps={{
                  className:
                    "block w-full min-h-[100px] resize-none outline-none",
                  disabled: onlyView
                }}
              />
            </FieldForm>
          </div>
        </div>
      </div>
      {!onlyView && <FooterNavigationButtons
        buttonLeft={{
          title: "Tạo nghiên cứu & lấy câu hỏi từ thư viện",
          iconRight: <Library />,
        }}
        buttonRight={{
          title: "Tạo nghiên cứu & tự soạn câu hỏi",
        }}
      />}
    </form>
  );
}

export default memo(InitSurvey);
