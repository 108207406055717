function Add({ className }) {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.81282 3.81282C7.2299 0.395728 12.7701 0.395728 16.1872 3.81282C19.6043 7.2299 19.6043 12.7701 16.1872 16.1872C12.7701 19.6043 7.2299 19.6043 3.81282 16.1872C0.395728 12.7701 0.395728 7.2299 3.81282 3.81282ZM14.9497 5.05025C12.2161 2.31658 7.78392 2.31658 5.05025 5.05025C2.31658 7.78392 2.31658 12.2161 5.05025 14.9497C7.78392 17.6834 12.2161 17.6834 14.9497 14.9497C17.6834 12.2161 17.6834 7.78392 14.9497 5.05025Z"
        fill="#999999"
      />
      <path
        d="M10 5.50669C9.48721 5.50669 9.07149 5.92241 9.07149 6.43522L9.07149 9.07149L6.43522 9.07149C5.92241 9.07149 5.50669 9.48721 5.50669 10C5.50669 10.5128 5.92241 10.9286 6.43522 10.9286L9.07149 10.9286L9.07149 13.5648C9.07149 14.0776 9.48721 14.4933 10 14.4933C10.5128 14.4933 10.9286 14.0776 10.9286 13.5648V10.9286L13.5648 10.9286C14.0776 10.9286 14.4933 10.5128 14.4933 10C14.4933 9.48721 14.0776 9.07149 13.5648 9.07149L10.9286 9.07149V6.43522C10.9285 5.92241 10.5128 5.50669 10 5.50669Z"
        fill="#999999"
      />
    </svg>
  );
}

export default Add;
