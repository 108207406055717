import { memo } from "react";

import GroupAnswer from "~/components/GroupAnswer";

function SingleChoice({ question, ...restProps }) {
  const { questionIndex } = restProps;

  return <GroupAnswer question={question} questionIndex={questionIndex} />;
}

export default memo(SingleChoice);
