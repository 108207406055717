import classNames from "classnames";
import { memo } from "react";
import { useController } from "react-hook-form";

function Input(props) {
  const {
    control,
    name,
    inputProps,
    type,
    value: valueProp,
    placeholder,
    className,
    transformValueOutput = (value) => value,
    transformValueInput = (onChange, e) => {
      onChange(e);
    },
    ...restProps
  } = props;

  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({ name, control, ...restProps });

  return (
    <>
      <input
        type={type || "text"}
        placeholder={placeholder}
        onChange={(e) => transformValueInput(onChange, e)}
        defaultValue={valueProp ? transformValueOutput(valueProp) : undefined}
        value={!valueProp ? transformValueOutput(value) : undefined}
        className={classNames(
          className,
          error && "border-[red]",
          "disabled:bg-[transparent]"
        )}
        {...inputProps}
      />
      {error && <p className="mt-2 text-[red] italic">{error.message}</p>}
    </>
  );
}

export default memo(Input);
