import classNames from "classnames/bind";
import { memo } from "react";

import BoxShadow from "~/components/BoxShadow";

function ChoiceResult(props) {
  const { className, questionIndex, question } = props;
  const ratioSelectDescendingSort = question?.question_choices?.sort(
    (a, b) => b.percent - a.percent
  );

  return (
    <BoxShadow className={classNames("p-5 grid gap-y-3", className)}>
      <p className="title-14">Câu hỏi {questionIndex + 1}</p>
      <p className="title-14 !font-normal py-5 px-3 bg-[#F5F5F5] rounded-[6px]">
        {question?.title}
      </p>
      <div className="title-table py-[6px] pl-3 pr-[48px] flex items-center font-semibold">
        <p className="flex-1">Đáp án câu hỏi</p>
        <p className="min-w-[85px] mr-[70px]">Số người chọn</p>
        <p className="min-w-[58px]">Tỉ lệ chọn</p>
      </div>
      {ratioSelectDescendingSort?.map((option, i) => (
        <div
          key={option?._id}
          className={classNames(
            "title-table py-[12px] pl-3 pr-[48px] flex items-center font-semibold rounded-[6px]",
            i === 0 ? "bg-info text-white" : 'bg-[#F5F5F5]'
          )}
        >
          <p className="flex-1 font-normal">{option?.value}</p>
          <p className="min-w-[85px] mr-[70px] text-right">
            {option?.count}/{option?.totalUsers}
          </p>
          <p className="min-w-[58px] text-right">{option?.percent ?? 0}%</p>
        </div>
      ))}
    </BoxShadow>
  );
}

export default memo(ChoiceResult);
