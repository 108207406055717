import classNames from "classnames";

function BoxShadow(props) {
  const { children, className, rounded, ...restProps } = props;

  return (
    <div
      className={classNames(
        "shadow-[0px_0px_8px_rgba(0,0,0,0.08)] rounded-[10px] bg-white",
        className
      )}
      style={{
        borderRadius: `${rounded}px`,
      }}
      {...restProps}
    >
      {children}
    </div>
  );
}

export default BoxShadow;
