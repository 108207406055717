/* eslint-disable react-hooks/exhaustive-deps */
import { useQuery } from "@tanstack/react-query";
import classNames from "classnames";
import { memo, useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import Clock from "~/assets/icon/Clock";
import BoxShadow from "~/components/BoxShadow";
import { colorSurveyStatus } from "~/helper";
import { surveyService } from "~/services/surveyService";
import { useSurveyStore } from "~/store/SurveyContext";
import { statusColorLabelClass } from "../BoxStatus";

const MILISECONDS_OF_ONE_DAY = 1000 * 60 * 60 * 24;
const MILISECONDS_OF_ONE_HOUR = 1000 * 60 * 60;
const HOUR_OF_A_DAY = 24;
const currentTime = new Date().getTime();

function BoxTime(props) {
  const timerId = useRef();
  const [timeCount, setTimeCount] = useState({});
  const { surveyId } = useParams();
  const {
    dataSurvey: { id = surveyId },
  } = useSurveyStore();

  const { data } = useQuery({
    queryKey: ["survey-progress"],
    queryFn: async () => await surveyService.list(`/${id}/survey-progress`),
  });

  const {
    status = "pending_approve",
    happenAt: happenAtData = "",
    estimatedTime = "",
  } = useMemo(() => {
    return data?.data || {};
  }, [data]);

  // const happenAtData = data?.data?.happenAt
  // const estimatedTime = data?.data?.estimatedTime;
  const happenAt = happenAtData && new Date(happenAtData);
  const timeRange = (currentTime - happenAt) / MILISECONDS_OF_ONE_DAY;

  const progressDay = useMemo(() => {
    return happenAtData ? ((timeRange / estimatedTime) * 100).toFixed(2) : 0;
  }, [data]);

  useEffect(() => {
    let dayRange = happenAt ? Math.floor(timeRange) : 0;
    let hourRange = happenAt
      ? Math.floor((timeRange - dayRange) * HOUR_OF_A_DAY)
      : 0;

    if (dayRange >= 5) {
      dayRange = 5;
      hourRange = 0;
    }

    setTimeCount({ dayRange, hourRange });
  }, [data]);

  useEffect(() => {
    if (!happenAt) return;

    timerId.current = setInterval(() => {
      setTimeCount((prev) => {
        const { dayRange, hourRange } = prev;

        if (hourRange + 1 >= 24)
          return {
            dayRange: dayRange + 1,
            hourRange: 0,
          };

        return {
          ...prev,
          hourRange: hourRange + 1,
        };
      });
    }, MILISECONDS_OF_ONE_HOUR);

    return () => {
      clearInterval(timerId.current);
    };
  }, [timeCount]);

  return (
    <BoxShadow
      className={classNames(
        "mt-5 flex min-h-[160px]",
        colorSurveyStatus[status]
      )}
    >
      <div className="flex items-center justify-center max-w-[162px] w-full bg-current rounded-[12px]">
        <Clock className="max-w-[84px]" />
      </div>
      <div className="flex items-center py-9 text-[14px] leading-[18px] text-[#B3B3B3] flex-1">
        <div className="mx-8 max-w-[160px] w-full">
          <p className="text-[28px] leading-[36px] text-[#333333] font-semibold">
            {estimatedTime} ngày
          </p>
          <p className="mt-[10px]">Dự kiến hoàn thành</p>
        </div>
        <div className="line h-full border-l border-[#E6E6E6]"></div>
        <div className="mx-8 max-w-[218px] w-full">
          <p className="text-[28px] leading-[36px] text-[#333333] font-semibold">
            {timeCount.dayRange} ngày {timeCount.hourRange} tiếng
          </p>
          <p className="mt-[10px]">Thời gian</p>
        </div>
        <div className="line h-full border-l border-[#E6E6E6]"></div>
        <div className="mx-8 max-w-[170px]">
          <p
            className={classNames(
              "text-[28px] leading-[36px] font-semibold",
              colorSurveyStatus[status]
            )}
          >
            {progressDay > 100 ? 100 : progressDay}%
          </p>
          <p className="my-[10px]">So với dự kiến tối đa</p>
          <div className="min-h-[6px] rounded-full bg-[#CCCCCC] overflow-hidden">
            <div
              className={classNames(
                "bg-current h-[6px]",
                statusColorLabelClass[status]
              )}
              style={{ width: `${progressDay > 100 ? 100 : progressDay}%` }}
            ></div>
          </div>
        </div>
      </div>
    </BoxShadow>
  );
}

export default memo(BoxTime);
