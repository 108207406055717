import classNames from "classnames";

function Delete({ className }) {
  return (
    <svg
      className={className}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.2043 6.52285C14.3538 6.52285 14.4895 6.5881 14.5967 6.69835C14.6967 6.8161 14.747 6.96235 14.7324 7.11684C14.7324 7.16784 14.3327 12.2228 14.1044 14.3505C13.9614 15.6563 13.1197 16.449 11.857 16.4708C10.8862 16.4925 9.93722 16.5 9.00284 16.5C8.01084 16.5 7.04072 16.4925 6.09905 16.4708C4.87874 16.4415 4.03626 15.6345 3.90059 14.3505C3.66572 12.2153 3.2733 7.16784 3.266 7.11684C3.25871 6.96235 3.30831 6.8161 3.40897 6.69835C3.50817 6.5881 3.65113 6.52285 3.80139 6.52285H14.2043ZM10.5485 1.5C11.2116 1.5 11.8039 1.96275 11.9753 2.62274L12.0978 3.17023C12.197 3.61648 12.5836 3.93223 13.0286 3.93223H15.2153C15.5071 3.93223 15.75 4.17447 15.75 4.48272V4.76772C15.75 5.06846 15.5071 5.31821 15.2153 5.31821H2.78539C2.49289 5.31821 2.25 5.06846 2.25 4.76772V4.48272C2.25 4.17447 2.49289 3.93223 2.78539 3.93223H4.97217C5.41639 3.93223 5.80298 3.61648 5.90291 3.17098L6.01743 2.65949C6.1954 1.96275 6.78112 1.5 7.45145 1.5H10.5485Z"
        fill="#999999"
      />
    </svg>
  );
}

export default Delete;
