/* eslint-disable react-hooks/exhaustive-deps */
import classNames from "classnames/bind";
import { memo, useState } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { useEffect } from "react";
import { toast } from "react-toastify";

import style from "./QuestionList.module.scss";
import QuestionSidebarLeft from "./components/QuestionSidebarLeft/QuestionSidebarLeft";
import QuestionSidebarRight from "./components/QuestionSidebarRight";
import QuestionListDetailContainer from "./components/QuestionListDetailContainer/QuestionListDetailContainer";
import FooterNavigationButtons from "~/components/FooterNavigationButtons";
import ArrowLeft from "~/assets/icon/ArrowLeft";
import { useSurveyStore } from "~/store/SurveyContext";
import { addDataSurvey } from "~/store/actions/SurveyActions";
import { useGlobalState } from "~/store/GlobalContext";
import { surveyService } from "~/services/surveyService";

const cx = classNames.bind(style);

function QuestionList() {
  const {
    globalState: { userId },
  } = useGlobalState();
  const { dispatch, dataSurvey, setStepOpen } = useSurveyStore();
  const { onlyView } = dataSurvey;
  // index question picking
  const [questionPicking, setQuestionPicking] = useState(0);
  const [questionPickingPosition, setQuestionPickingPosition] = useState(0);
  const navigate = useNavigate();
  const methods = useForm({
    defaultValues: {
      questions: dataSurvey.newQuestions || dataSurvey.questions,
    },
  });

  const { control, watch } = methods;
  const methodsUseFieldArray = useFieldArray({
    control,
    name: "questions",
  });

  // watch(({ questions }) => {
  //   console.log("questions: ", questions);
  // });

  const {
    mutate,
    isError,
    error,
    isSuccess,
    data: dataSurveyCreated,
  } = useMutation({
    mutationFn: async (data) => await surveyService.create("", data),
  });

  useEffect(() => {
    const surveyId = dataSurveyCreated?.data?.id;

    if (isSuccess) {
      dispatch(addDataSurvey({ id: surveyId }));
      navigate(`/final-preview`);
      setStepOpen("all");
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      toast.error(error.message);
    }
  }, [isError]);

  const submitDataQuestions = () => {
    let questions = watch("questions");
    const formatQuestionsData = questions.map((question) => {
      delete question.id;

      return {
        ...question,
      };
    });

    dispatch(addDataSurvey({ newQuestions: formatQuestionsData }));
    mutate({
      ...dataSurvey,
      newQuestions: formatQuestionsData,
      memberId: userId,
    });
  };

  return (
    <div className={cx("wrapper", "px-5 max-w-[1200px] w-full mx-auto mb-5")}>
      <div className="flex">
        <FormProvider {...methodsUseFieldArray} {...methods}>
          <QuestionSidebarLeft
            questionPicking={questionPicking}
            setQuestionPicking={setQuestionPicking}
          />
          <div className="flex-1 mx-5">
            <QuestionListDetailContainer
              questionPicking={questionPicking}
              setQuestionPicking={setQuestionPicking}
              setQuestionPickingPosition={setQuestionPickingPosition}
            />
          </div>
          <QuestionSidebarRight
            questionPicking={questionPicking}
            questionPickingPosition={questionPickingPosition}
          />
        </FormProvider>
      </div>
      {!onlyView && (
        <FooterNavigationButtons
          buttonLeft={{
            title: "Quay về",
            iconLeft: <ArrowLeft />,
            type: "link",
            to: "/segment",
          }}
          buttonRight={{
            title: "Hoàn thành bảng câu hỏi",
            onClick() {
              submitDataQuestions();
            },
          }}
        />
      )}
    </div>
  );
}

export default memo(QuestionList);
