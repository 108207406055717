import { Fragment } from "react";
import DefaultLayout from "~/Layout/DefaultLayout";

function showLayout(layout) {
  if (layout === null) {
    return Fragment;
  }

  if (layout) {
    return layout;
  }

  return DefaultLayout;
}

const createAction = actionName => (payload => ({
  action: actionName,
  payload
}))

const genderOptions = [
  {
    value: 'male',
    label: 'Nam'
  },
  {
    value: 'female',
    label: 'Nữ'
  },
  {
    value: 'other',
    label: 'Khác'
  },
]

const advancedInfoKeyName = {
  job: 'Nghề nghiệp',
  income: 'Thu nhập',
  marital: 'Tình trạng hôn nhân',
}

const convertDataCities = (data) => {
  const cities = data?.data || data || []
  const newFormatData = cities?.map(city => ({
    value: city.code,
    label: city.name
  }))

  return newFormatData
}

const mapNewData = (state, type, valueChange) => {
  const newTarget = state.map((targetItem) => {
    if (targetItem.name === type) {
      return {
        ...targetItem,
        ...valueChange
      };
    }

    return targetItem;
  });

  return newTarget
}

export const staticDataCheckboxCategories = [
  {
    key: "jobs",
    url: "/jobs",
  },
  {
    key: "incomes",
    url: "/incomes",
  },
  {
    key: "marital",
    url: "/marital_states",
  },
];

export function convertToVndFormat(price) {
  return new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(price).slice(0, -2).replace(/\./g, ',');
}

export function convertNumberThousand(number) {
  return number?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
}

export function reverseString(string = '') {
  let stringReverse = string.replace(/\./g)
  return stringReverse
}

export const convertToNameTypeQuestion = (type) => {
  let arrWords = type.split('_')
  arrWords = arrWords.map(word => {
    let [firstLetter, ...restLetter] = word

    return `${firstLetter.toUpperCase()}${restLetter.join('')}`
  })

  return arrWords.join(' ')
}

export const convertSecondToMinute = (time = 0) => {
  return time / 60
}

export const convertSecondToDay = (time = 0) => {
  return Math.floor(time / (60 * 24))
}

export { showLayout, createAction, genderOptions, advancedInfoKeyName, convertDataCities, mapNewData };

export const configHeaderUploadFile = (config) => {
  return config
}

export const colorSurveyStatus = {
  pending_approve: 'text-warning',
  happenning: 'text-info',
  completed: 'text-primary',
}