function ArrowRight({ className }) {
  return (
    <svg
      className={className}
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.0297 3.86563C12.6045 3.43411 11.914 3.43411 11.4889 3.86563C11.0654 4.29541 11.0654 4.99096 11.4889 5.42074L14.9157 8.89913L3.46296 8.89914C2.85918 8.89914 2.375 9.3951 2.375 10.0003C2.375 10.6056 2.85918 11.1015 3.46296 11.1015L14.9157 11.1015L11.4889 14.5799C11.0654 15.0097 11.0654 15.7052 11.4889 16.135C11.914 16.5665 12.6045 16.5665 13.0297 16.135L18.3074 10.7779C18.5111 10.5711 18.625 10.2914 18.625 10.0003C18.625 9.70927 18.5111 9.42951 18.3074 9.22277L13.0297 3.86563Z"
        fill="white"
      />
    </svg>
  );
}

export default ArrowRight;
