import classNames from "classnames/bind";
import { memo } from "react";
import { useFormContext, useWatch } from "react-hook-form";

import Button from "~/components/Button";
import Input from "~/components/Input";
import { useSurveyStore } from "~/store/SurveyContext";

function LevelChoice(props) {
  const { questionIndex } = props;
  const { control, update } = useFormContext();
  const {
    dataSurvey: { onlyView },
  } = useSurveyStore();
  const question = useWatch({
    control,
    name: `questions.${questionIndex}`,
  });
  const maxPoint = +question.options[1].value;

  const handleUpdatePoint = (point) => {
    question.options[1].value = point;
    update(questionIndex, question);
  };

  return (
    <>
      <div className="flex items-center px-[10px] gap-[14px]">
        {Array(10)
          .fill(0)
          .map((_, i) => (
            <div className="flex items-center gap-[14px]" key={i}>
              {i === 0 && <p>Từ</p>}
              <Button
                variant={i + 1 === maxPoint ? "fill" : "outline"}
                className={classNames(
                  "w-10 h-10 rounded-[6px] font-semibold transition-all hover:bg-primary hover:text-white",
                  i + 1 !== maxPoint
                    ? "border-[#E6E6E6] text-[#595959]"
                    : "text-white"
                )}
                onClick={i > 0 ? () => handleUpdatePoint(i + 1) : undefined}
                disabled={onlyView}
              >
                {i + 1}
              </Button>
              {i === 0 && <p>Đến</p>}
            </div>
          ))}
      </div>
      <div className="level-label  mt-3">
        <p>
          <span className="inline-block font-semibold w-[10px] text-[#595959]">
            1
          </span>
          <Input
            control={control}
            name={`questions.${questionIndex}.options[0].title`}
            className="placeholder:text-[#B3B3B3] outline-none ml-[10px]"
            placeholder="Nhãn (không bắt buộc)"
            inputProps={{
              disabled: onlyView,
            }}
          />
        </p>
        <p className="mt-[6px]">
          <span className="inline-block font-semibold w-[10px] text-[#595959]">
            {maxPoint}
          </span>
          <Input
            control={control}
            name={`questions.${questionIndex}.options[1].title`}
            className="placeholder:text-[#B3B3B3] outline-none ml-[10px]"
            placeholder="Nhãn (không bắt buộc)"
            inputProps={{
              disabled: onlyView,
            }}
          />
        </p>
      </div>
    </>
  );
}

export default memo(LevelChoice);
