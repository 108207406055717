function Library({ className }) {
  return (
    <svg
      className={className}
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path className="fill-current"
        d="M14.0774 9.75606L10.744 6.4227C10.4186 6.09726 10.4186 5.56963 10.744 5.24419L14.0774 1.91083C14.4028 1.58539 14.9305 1.58539 15.2559 1.91083L18.5893 5.24419C18.9147 5.56963 18.9147 6.09726 18.5893 6.4227L15.2559 9.75606C14.9305 10.0815 14.4028 10.0815 14.0774 9.75606Z"
        fill="#00D68F"
      />
      <path className="fill-current"
        d="M3 3.3334C3 2.87316 3.3731 2.50006 3.83333 2.50006H8.83333C9.29357 2.50006 9.66667 2.87316 9.66667 3.3334V8.3334C9.66667 8.79363 9.29357 9.16673 8.83333 9.16673H3.83333C3.3731 9.16673 3 8.79363 3 8.3334V3.3334Z"
        fill="#00D68F"
      />
      <path className="fill-current"
        d="M3 11.6667C3 11.2065 3.3731 10.8334 3.83333 10.8334H8.83333C9.29357 10.8334 9.66667 11.2065 9.66667 11.6667V16.6667C9.66667 17.127 9.29357 17.5001 8.83333 17.5001H3.83333C3.3731 17.5001 3 17.127 3 16.6667V11.6667Z"
        fill="#00D68F"
      />
      <path className="fill-current"
        d="M12.1667 10.8334C11.7064 10.8334 11.3333 11.2065 11.3333 11.6667V16.6667C11.3333 17.127 11.7064 17.5001 12.1667 17.5001H17.1667C17.6269 17.5001 18 17.127 18 16.6667V11.6667C18 11.2065 17.6269 10.8334 17.1667 10.8334H12.1667Z"
        fill="#00D68F"
      />
    </svg>
  );
}

export default Library;
