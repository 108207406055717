import classNames from "classnames/bind";
import { memo } from "react";

import HeadingBig from "~/components/HeadingBig";
import RadioGroup from "~/components/RadioGroup";
import { optionsQuantity } from "~/helper/FakeData";
import style from "../../Segment.module.scss";
import Input from "~/components/Input";
import { useSurveyStore } from "~/store/SurveyContext";
import { convertNumberThousand } from "~/helper";

const cx = classNames.bind(style);

function ResearchQuantity(props) {
  const { control } = props;
  const {
    dataSurvey: { onlyView },
  } = useSurveyStore();

  const handleTransformValueInput = (onChange, e) => {
    const {
      nativeEvent: { data: characterInput },
      target: { value = "" },
    } = e;

    // replace dots when convert not true, Ex: 5.0.000 => 50000
    let valueFormat = value.replace(/\./g, '')
    if (valueFormat > 5000) valueFormat = 5000

    e.target.value = convertNumberThousand(valueFormat);

    // Do not allow input text
    if (isNaN(Number(characterInput))) return;
    onChange(e);
  }

  return (
    <div className={cx("section")}>
      <HeadingBig className={cx("heading")} title="Số lượng mẫu nghiên cứu" />
      <Input
        control={control}
        name="targetNumber"
        className="block my-[30px] text-primary mx-auto text-[18px] leading-[23px] font-semibold max-w-[120px] h-[48px] px-6 w-fit border border-current rounded-full outline-none text-center"
        transformValueInput={handleTransformValueInput}
        inputProps={{
          disabled: onlyView,
        }}
      />
      <div className="flex justify-between items-center min-h-[32px]">
        <p className="title-14">Gợi ý số lượng mẫu </p>
        <RadioGroup
          control={control}
          name="targetNumber"
          options={optionsQuantity}
          columns={optionsQuantity.length}
          className="gap-x-5"
          disabled={onlyView}
        />
      </div>
    </div>
  );
}

export default memo(ResearchQuantity);
