/* eslint-disable react-hooks/exhaustive-deps */
import classNames from "classnames/bind";
import { memo, useEffect } from "react";
import { useFormContext } from "react-hook-form";

import RadioInput from "~/components/RadioInput";
import style from "./PaymentMethods.module.scss";
import VisaPayment from "../VisaPayment";
import BankPayment from "../BankPayment";
import useSlideToggle from "~/hooks/useSlideToggle";

const cx = classNames.bind(style);

function PaymentMethods(props) {
  const { toggle, contentRef, slideOpen, slideClose } = useSlideToggle();
  const { control, watch } = useFormContext();
  const paymentMethod = watch("paymentMethod");

  useEffect(() => {
    if (paymentMethod === "support") {
      slideOpen();

      return
    }

    slideClose()
  }, [watch("paymentMethod")]);

  return (
    <div className="wrapper mt-5">
      <VisaPayment />
      <BankPayment />
      <div className={cx("method", paymentMethod === "support" && 'active')}>
        <div className={cx("header", "cursor-pointer")} onClick={toggle}>
          <RadioInput
            control={control}
            name="paymentMethod"
            label="Yêu cầu hỗ trợ ký hợp đồng, thanh toán & xuất hoá đơn "
            value="support"
            className="font-semibold"
          />
        </div>
        <p className="color-desc pl-5 overflow-hidden" ref={contentRef}>
          Dành cho doanh nghiệp có nhu cầu thanh toán từ công ty. DophinX hỗ trợ
          xuất hoá đơn, thanh toán ngân hàng và các dịch vụ hỗ trợ doanh nghiệp
          khác. Bộ phận chăm sóc DophinX sẽ liên lạc với bạn trong 12-24 giờ từ
          khi nhận yêu cầu này.
        </p>
      </div>
    </div>
  );
}

export default memo(PaymentMethods);
