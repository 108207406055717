import classNames from "classnames/bind";
import { memo } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";

import style from "./Checkbox.module.scss";

const cx = classNames.bind(style);

function Checkbox(props) {
  const { control, value, label, name, className, checkboxGroup, inputProps } = props;
  const methods = useFormContext();
  const controlFromContext = methods?.control;

  const checkboxesSelected = useWatch({
    control: control || controlFromContext,
    name: name,
  });

  const handleCheckboxChange = (e, onChange) => {
    if (e.target.checked) {
      onChange([
        ...checkboxesSelected,
        {
          key: e.target.value,
          label: label,
        },
      ]);
    } else {
      onChange(
        checkboxesSelected.filter((selected) => selected.key !== e.target.value)
      );
    }
  };

  return (
    <Controller
      control={control || controlFromContext}
      name={name}
      render={({ field: { onChange, ref }, fieldState: { error } }) => (
        <>
          <label
            className={cx(
              "wrapper",
              "flex items-center cursor-pointer w-fit",
              className
            )}
          >
            <input
              type="checkbox"
              className="hidden"
              value={value}
              onChange={(e) => {
                Array.isArray(checkboxesSelected)
                  ? handleCheckboxChange(e, onChange)
                  : onChange(e);
              }}
              checked={
                Array.isArray(checkboxesSelected)
                  ? checkboxesSelected.some(
                      (item) => item?.key === value || item === value
                    )
                  : checkboxesSelected
              }
              ref={ref}
              {...inputProps}
            />
            <div
              className={cx("checkmark", "flex items-center justify-center mr-2")}
            >
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.7125 0.75C11.8313 0.75 13.25 2.2375 13.25 4.45V9.55688C13.25 11.7625 11.8313 13.25 9.7125 13.25H4.29375C2.175 13.25 0.75 11.7625 0.75 9.55688V4.45C0.75 2.2375 2.175 0.75 4.29375 0.75H9.7125ZM9.6125 5.125C9.4 4.9125 9.05 4.9125 8.8375 5.125L6.25625 7.70625L5.1625 6.6125C4.95 6.4 4.6 6.4 4.3875 6.6125C4.175 6.825 4.175 7.16875 4.3875 7.3875L5.875 8.86875C5.98125 8.975 6.11875 9.025 6.25625 9.025C6.4 9.025 6.5375 8.975 6.64375 8.86875L9.6125 5.9C9.825 5.6875 9.825 5.34375 9.6125 5.125Z"
                  fill="#00D68F"
                />
              </svg>
            </div>
            <div>{label}</div>
          </label>
          {!checkboxGroup && error && <p className="mt-2 text-[red] italic">{error?.message}</p>}
        </>
      )}
    />
  );
}
export default memo(Checkbox);
