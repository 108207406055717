import classNames from "classnames";
import { memo } from "react";
import Select from "react-dropdown-select";
import { Controller } from "react-hook-form";

import './SelectDropdownMultiCities.scss'

function SelectDropdownMultiCities(props) {
  const { control, name, options, className, defaultValue, ...restProps } = props;

  return (
    <div className={className}>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange } }) => {
          return (
            <Select
              onChange={onChange}
              options={options}
              values={defaultValue || []}
              multi
              placeholder='Thêm địa điểm khác'
              className="select-cities-custom !items-end !shadow-none !min-h-[114px] !py-4 !px-6 !border-none bg-[#F5F5F5] !rounded-[6px]"
              {...restProps}
            />
          );
        }}
      />
    </div>
  );
}

export default memo(SelectDropdownMultiCities);
