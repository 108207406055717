import classNames from "classnames/bind";
import { memo } from "react";

import style from "./HeadingBig.module.scss";

const cx = classNames.bind(style);

function HeadingBig({ className, title, ...props }) {
  return <h3 className={cx("heading", className)} {...props}>{title}</h3>;
}

export default memo(HeadingBig);
