/* eslint-disable react-hooks/exhaustive-deps */
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { useState, useMemo } from "react";
import { useForm } from "react-hook-form";

import { getData } from "~/api/fetchApi";
import { useSurveyStore } from "~/store/SurveyContext";
import useSlideToggle from "./useSlideToggle";
import { genderOptions } from "~/helper";

function useBasicInfo() {
  const {
    dataSurvey: { segment },
  } = useSurveyStore();
  const [locationActive, setLocationActive] = useState(() => {

    const placeData = segment.find(
      (segmentItem) => segmentItem.name === "place"
    );
    const { only = [], ignore = [] } = placeData;

    if (only.length > 0) return 'only'

    if (ignore.length > 0) return 'ignore'

    return "all";
  });

  const ageRange = useMemo(() => {
    return segment.find((segmentItem) => segmentItem.name === "age");
  }, []);

  const genderStoreData = useMemo(() => {
    const genderArr = segment.find((segmentItem) => segmentItem.name === "gender").value;
    const genderInit = genderOptions.filter(gender => genderArr.includes(gender.value)).map(gender => ({
      ...gender,
      key: gender.value
    }))

    return genderInit
  }, []);

  const { data: dataCities } = useQuery({
    queryKey: ["cities"],
    queryFn: async () => getData("/static_data/provinces"),
  });

  const citiesSelectedStoreData = useMemo(() => {
    let dataCitiesAPI = dataCities?.data || []
    const placeInfo = segment.find(
      (segmentItem) => segmentItem.name === "place"
    );

    if (placeInfo.ignore.length > 0) {
      return dataCitiesAPI.filter(city => placeInfo.ignore.includes(city.code))
    }

    if (placeInfo.only.length > 0) {
      return dataCitiesAPI.filter(city => placeInfo.only.includes(city.code))
    }
  }, []);

  const { watch, control } = useForm({
    defaultValues: {
      citiesSelected: citiesSelectedStoreData || [],
      gender: genderStoreData,
    },
  });

  const { contentRef, slideOpen, slideClose, isOpen } = useSlideToggle()

  useEffect(() => {
    if (locationActive === 'all') slideClose()
    if (locationActive !== 'all') slideOpen()
  }, [locationActive]);

  return {
    locationActive,
    setLocationActive,
    ageRange, watch, control, dataCities, contentRef, isOpen
  };
}

export default useBasicInfo;