import { memo } from "react";

function Setting(props) {
  const { className, active } = props;

  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.7171 2C13.4734 2 14.1581 2.42 14.5362 3.04C14.7202 3.34 14.8428 3.71 14.8121 4.1C14.7917 4.4 14.8837 4.7 15.0472 4.98C15.5684 5.83 16.7232 6.15 17.6225 5.67C18.6343 5.09 19.9117 5.44 20.4942 6.43L21.1789 7.61C21.7717 8.6 21.4446 9.87 20.4227 10.44C19.554 10.95 19.2474 12.08 19.7686 12.94C19.9321 13.21 20.1161 13.44 20.4022 13.58C20.7599 13.77 21.0359 14.07 21.23 14.37C21.6082 14.99 21.5775 15.75 21.2096 16.42L20.4942 17.62C20.1161 18.26 19.4109 18.66 18.6854 18.66C18.3277 18.66 17.9291 18.56 17.6021 18.36C17.3364 18.19 17.0298 18.13 16.7028 18.13C15.691 18.13 14.8428 18.96 14.8121 19.95C14.8121 21.1 13.8719 22 12.6967 22H11.3068C10.1214 22 9.18115 21.1 9.18115 19.95C9.16071 18.96 8.31249 18.13 7.30075 18.13C6.9635 18.13 6.65692 18.19 6.40143 18.36C6.0744 18.56 5.66562 18.66 5.31815 18.66C4.58234 18.66 3.87719 18.26 3.49907 17.62L2.79392 16.42C2.41579 15.77 2.39535 14.99 2.77348 14.37C2.93699 14.07 3.24358 13.77 3.59104 13.58C3.87719 13.44 4.06114 13.21 4.23488 12.94C4.74586 12.08 4.43927 10.95 3.5706 10.44C2.55887 9.87 2.23184 8.6 2.81436 7.61L3.49907 6.43C4.0918 5.44 5.35903 5.09 6.38099 5.67C7.27009 6.15 8.4249 5.83 8.9461 4.98C9.10961 4.7 9.20159 4.4 9.18115 4.1C9.16071 3.71 9.27313 3.34 9.4673 3.04C9.84542 2.42 10.5301 2.02 11.2762 2H12.7171ZM12.012 9.18C10.4075 9.18 9.10961 10.44 9.10961 12.01C9.10961 13.58 10.4075 14.83 12.012 14.83C13.6164 14.83 14.8837 13.58 14.8837 12.01C14.8837 10.44 13.6164 9.18 12.012 9.18Z"
        fill={active ? "" : "#595959"}
      />
    </svg>
  );
}

export default memo(Setting);
