/* eslint-disable array-callback-return */
import classNames from "classnames/bind";
import { memo } from "react";
import { mediaDomain } from "~/api/axiosClient";
import Label from "~/assets/icon/Label";
import DashboardBoxItem from "../DashboardBoxItem";
import { Link, useParams } from "react-router-dom";

const statusColorLabelClass = {
  happenning: "text-info",
  completed: "text-primary",
  pending_approve: "text-[#999999]",
}; 

function DashboardCategory(props) {
  const { surveyStatus } = useParams();
  const userName = localStorage.getItem("userName");
  const { categoryName, surveyList, type } = props;

  return (
    <div className={classNames("wrapper", "mt-[30px]")}>
      <div className="title flex items-center">
        <Label
          className={classNames('w-5', statusColorLabelClass[type])}
        />
        <p className="text-[16px] leading-[20px] font-semibold ml-[10px]">
          {categoryName}
        </p>
      </div>

      {
        surveyList.length === 0 && 
        <p className="mt-5">Không có dữ liệu nghiên cứu</p>
      }

      <ul className="grid grid-cols-5 mt-5 gap-5">
        {surveyList.map((item, i) => {
          if (i >= 10 && !surveyStatus) return;

          if (i === 9 && !surveyStatus) {
            return (
              <li key={i} className="max-w-[216px] w-full text-[14px] leading-[18px] text-[#B3B3B3] hover:text-primary transition cursor-pointer font-semibold bg-white rounded-[10px] overflow-hidden">
                <Link to={`/${type}`} className="flex items-center justify-center w-full h-full">Xem thêm</Link>
              </li>
            );
          }

          return (
            <DashboardBoxItem
              key={i}
              image={`${mediaDomain}/${item.image}`}
              title={item.name}
              description={item.description}
              questionQuantity={item.questions.length}
              createBy={userName}
              status={item.status}
              numberStart={item.totalComplete || 0}
              numberEnd={item.targetNumber || 0}
              date={item.date}
              id={item.id}
            />
          );
        })}
      </ul>
    </div>
  );
}

export default memo(DashboardCategory);
