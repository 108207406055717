import classNames from "classnames/bind";
import { memo } from "react";

import ArrowRight from "~/assets/icon/ArrowRight";
import Button from "~/components/Button";
import style from "./FooterNavigationButtons.module.scss";

const cx = classNames.bind(style);

function FooterNavigationButtons(props) {
  const { buttonLeft, buttonRight } = props;

  return (
    <div className="flex justify-center items-center mt-5 pt-3 pb-6">
      <Button
        variant="outline"
        className={cx(
          "button-left",
          "bg-white min-h-[48px] min-w-[334px] font-semibold hover:brightness-95"
        )}
        {...buttonLeft}
      ></Button>
      <Button
        variant="fill"
        iconRight={<ArrowRight className={cx("arrow")} />}
        className={cx(
          "button-right",
          "min-h-[48px] min-w-[334px] ml-5 font-semibold border-primary hover:brightness-95"
        )}
        {...buttonRight}
      ></Button>
    </div>
  );
}

export default memo(FooterNavigationButtons);
